/*eslint-disable*/
import React, {useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
  paginationContainer: {
    marginTop:"2em !important",
  }
};

const useStyles = makeStyles(newStyles);

// Our table component
function SimplePagination({ page, pageSize, pageTotal, setPage, setPageSize}) {

  const classes = useStyles();

  const canPreviousPage = () => page > 1
  const canNextPage = () => page < pageTotal

  return (
    <div className="ReactTable">
      <div className={"pagination-top " + classes.paginationContainer}>
        <div className="-pagination">
          <div className="-previous">
            <button
              type="button"
              onClick={() => setPage(page-1)}
              disabled={!canPreviousPage()}
              className="-btn"
            >
              Previous
            </button>
          </div>

          <div className="-center">
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={6} md={4}>
                <FormControl
                  fullWidth
                  className={
                    classes.selectFormControl +
                    " " +
                    classes.formControlMargins
                  }
                >
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={page}
                    onChange={(event) => {
                      setPage(event.target.value);
                    }}
                    inputProps={{
                      name: "pageSelect",
                      id: "page-select",
                      "data-testid": "page-select"
                    }}
                  >
                    {Array(pageTotal).fill("").map((ele, index) => {
                      return (
                        <MenuItem
                          key={index+1}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={index+1}
                        >
                          Page {index + 1}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <FormControl
                  fullWidth
                  className={
                    classes.selectFormControl +
                    " " +
                    classes.formControlMargins
                  }
                >
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={pageSize}
                    onChange={(event) => {
                      setPageSize(event.target.value);
                      setPage(1);
                    }}
                    inputProps={{
                      name: "numberOfRows",
                      id: "number-of-rows",
                    }}
                    title={"Page Size Select"}
                  >
                    {[5, 10, 20, 25, 50, 100].map((prop) => {
                      return (
                        <MenuItem
                          key={prop}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={prop}
                        >
                          {prop} rows
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </div>

          <div className="-next">
            <button
              type="button"
              onClick={() => setPage(page+1)}
              disabled={!canNextPage()}
              className="-btn"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimplePagination;
