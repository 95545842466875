import React, {useEffect, useRef, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import Wizard from "./Wizard.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Step1 from "./LabelStep1";
import Step2 from "./LabelStep2";
import Step3 from "./LabelStep3";
import Step4 from "./LabelStep4";

import QzPrintService from "services/QzPrintService";
import QzPrint, { launchQZ } from "./QzPrint";

const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center",
    },
    cardCategory: {
        margin: "0",
        color: "#999999",
    },
};

const useStyles = makeStyles(styles);

export default function PrintLabelPanel() {
    const [keyBase,setKeyBase] = useState(0)
    useEffect(()=> {
        console.log("PRINT LABEL PANEL MOUNTED")

        new QzPrintService().setupPrintRequest()
        launchQZ()
    }, [])

    const resetComponent = ()=> {
        setKeyBase(keyBase+1)
    }

    const classes = useStyles();
    return (
        <Wizard
            // validate
            key={keyBase}
            resetWizard={resetComponent}
            color="primary"
            steps={[
                { stepName: "Scan Item ", stepComponent: Step1, stepId: "preliminary" },
                { stepName: "Manage Split Order", stepComponent: Step2, stepId: "split" },
                { stepName: "Manual Shipping", stepComponent: Step3, stepId: "manual" },
                { stepName: "Reprint", stepComponent: Step4, stepId: "reprint" },

            ]}
            title="Print Shipping Labels"
            subtitle=""
            finishButtonClick={(e) => alert('Done!')}
        />
    )
}