/*eslint-disable*/
import React, {useEffect} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import SimplePagination from "app_components/SimplePagination.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  scanFormTable: {
    whiteSpace: "pre-line",
    '& td:nth-child(1)': {
      width: "1px",
    },
    '& td:nth-child(2)': {
      width: "100%",
    }
  },
  tableWidth: {
    width: "100%"
  }
};

const useStyles = makeStyles(newStyles);

// Our table component
function PreviousScanFormsTable({manifests, paginationProps}) {
  const classes = useStyles()
  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <div className={classes.tableWidth}>
              <h4 className={classes.cardIconTitle}>Previously Generated Manifests</h4>
              <SimplePagination {...paginationProps}/>
          </div>
          <div className={classes.scanFormTable + " " + classes.tableWidth}>
              <Table
                  tableHeaderColor="primary"
                  tableHead={["Date", "Carrier", "Open"]}
                  tableData={Object.values(manifests)}
                  colorsColls={["primary"]}
              />
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
}


export default PreviousScanFormsTable;