import React, {useEffect} from "react";
import {Link} from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import {infoColor} from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card.js";
import Table from "components/Table/Table.js";

// app services
import PrintStationService from "services/PrintStationService";
import PrinterService from "services/PrinterService";
import CardHeader from "../../components/Card/CardHeader";
import CardText from "../../components/Card/CardText";


const styles = {
    submitButton: {
        "& span span": {
            display: "none"
        }
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    warningText: {
        color: infoColor,
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    inputAdornmentIcon: {
        color: "#555",
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px",
    },
    btnContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        columnGap: "20px",
        '& button': {
            flexGrow: "1",
            width: "auto !important",
            maxWidth: "100%",
        }
    },
    btnStyle: {
        width: "160px",
        borderRadius: "3px"
    },

    cancelLinkCont: {
        position: "relative",
        float: "right",
        padding: "20px",
        '& a:link, & a:visited, & a:hover, & a:active': {
            color: "#3cc",
        },
        '& a:hover': {
            textDecoration: "underline",
        }
    },
    binLabelForm: {
        display: "flex",
        alignItems: "baseline",
        '& > div': {
            flexGrow: "1",
            marginBottom: "0",
        },
        '& button': {
            width: "80px",
            marginLeft: "15px",
        },
    },
    orBlock: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        '& h4': {
            whiteSpace: "nowrap",
            textAlign: "center",
        }
    },
    center: {
        textAlign: "center",
    },
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
};

const useStyles = makeStyles(styles);

const Step2 = React.forwardRef((props, ref) => {
    const classes = useStyles();

    const {currentStep} = props;
    // const [splitOrderStatus, setSplitOrderStatus] = React.useState(-1);
    const [binStatus, setBinStatus] = React.useState({})
    const [newBinName, setNewBinName] = React.useState("")
    const callHome = props.callHome;

    React.useEffect(()=> {
        if(props.allStates.preliminary?.splitOrderStatus?.status!== undefined){
            switch(props.allStates.preliminary?.splitOrderStatus?.status) {
                case 2:
                    // alert("FIRST ITEM FOR SPLIT");
                    break;
                case 3:
console.log('already assigned to pin - putting in there - first == false')
                    putItemInBin(props.allStates.preliminary.splitOrderStatus.bin.location_name,false);
                    break;
                default:
                    console.log('Uncaught split order status: '+props.allStates.preliminary?.splitOrderStatus?.status);
            }
        }
    }, [props.allStates.preliminary?.splitOrderStatus?.status])
    
    React.useEffect(()=> {
        if (Object.entries(binStatus).count === 0)
            return;

    }, [binStatus])

    useEffect(()=> {
        console.log('XXXXX');
        console.log(currentStep);
    },[])

    // useEffect(()=> {
    //   setSplitOrderStatus(props.allStates.preliminary?.splitOrderStatus?.status);
    // },[props.allStates.preliminary?.splitOrderStatus?.status])

    const putItemInBin = async (binName, first) => {
        console.log('OFFICIAL: putItemInBin');
        const firstFlag = first ? 'true' : 'false'
        new PrintStationService().putItemInBin(props.allStates.preliminary.poiid, binName, `create=${firstFlag}`)
            .then((data) => {
                if(data.status == 'error'){
                    switch (data.code) {
                        case 1004:
                            alert(data.message);
                            break
                        case 1005:
                            alert(data.message)
                            break;
                    }
                    setNewBinName('');
                    return;
                }
                if(first){
                    callHome('step2','placement_finished')
                }
                else {
                    setBinStatus({
                        bin: data.response.bin,
                        binStatus: data.response.bin_status,
                        binItems: data.response.bin_items,
                        missingItems: data.response.missing_items
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const printShippingLabel = (data) => {
        new PrinterService().printShippingLabel(data)
    }

    const bypassSplitOrder = async () => {
        new PrintStationService().bypassSplitOrder(props.allStates.preliminary.poiid)
            .then((data) => {
                console.log('HERE RBX');
                console.log(data.response);
                shipPO(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleCancel = ()=> {
        callHome('step2','cancel');
    }

    const shipPO = async (flag) => {
        const addFlag = flag ? 'add_label=true' : 'add_label=false'
        new PrintStationService().shipPO(props.allStates.preliminary.poiid, addFlag)
            .then((data) => {
                if(data.status == 'error'){
                    alert(data.message);
                    callHome('step2','error');
                    return;
                }
                printShippingLabel(data);
                callHome('step2','label_printed');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            return isValidated();
        },
        sendState: () => {
            console.log('step2 sendState');
            return sendState();
        },
        resetState: ()=> {
            console.log('step2 resetState');
            setBinStatus({});
            setNewBinName('');
            // setSplitOrderStatus(-1)
        },
        state: {
            binStatus
        },
    }));
    const sendState = () => {
        return {
            binStatus
        };
    };
    const isValidated = () => {
        return true;
    };

    const thisRoad = () => {
        return (<h1>this rowad</h1>)
    }

    const handleBinSubmit = (event) => {
        event.preventDefault();
        putItemInBin(newBinName, true);
        // callHome('step2','placement_finished')
    }

    const handleBypass = (event) => {
        event.preventDefault();
        bypassSplitOrder();
    }

    const handlePartial = (event) => {
        event.preventDefault();
        callHome('step2','start_partial')
    }

    const handleBinAdditionFinish = (event) => {
        event.preventDefault();
        if(binStatus.binStatus == 'full'){
            shipPO(false);
        } else {
            callHome('step2','placement_finished - bin not full')
        }
    }

    const renderRemaining = () => {
        if(binStatus?.missingItems && binStatus.missingItems.length > 0){
            return <Card style={{paddingTop: '0px', marginTop: '0px', width: '100%'}}>
                <Table
                  tableHead={[
                      "Item Code",
                      "Description",
                  ]}
                  tableData={
                      binStatus.missingItems.map((item, key) => {
                          return item.split(" - ")
                      })
                  }
                />
            </Card>
        } else {
            return <p>None</p>
        }
    }

    const renderItemsInBin = () => {
        if(binStatus?.binItems){
            return <Card style={{paddingTop: '0px', marginTop: '0px', width: '100%'}}>
                <Table
                  tableHead={[
                      "Item Code",
                      "Description",
                  ]}
                  tableData={
                      binStatus.binItems.map((item, key) => {
                          return item.split(" - ")
                      })
                  }
                />
            </Card>
        }
    }

    if(props.allStates.preliminary?.splitOrderStatus?.status == 2)
        return (
            <GridContainer justifyContent="center" className={classes.gridMarg}>
                <GridItem xs={12} lg={6} style={{display: "flex", alignItems: "stretch"}}>
                    <Card style={{padding: "10px 16px", justifyContent: "center"}}>
                        <h4>First item of SPLIT ORDER, Please scan the BIN LABEL:</h4>
                        <form onSubmit={handleBinSubmit} className={classes.binLabelForm} >
                            <CustomInput
                              labelText={
                                  <span>Bin Label</span>
                              }
                              id="bin"
                              inputProps={{
                                  value: newBinName,
                                  autoFocus: true,
                                  onChange: (event) => {
                                      setNewBinName(event.target.value)
                                  },
                              }}
                            />
                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                        </form>
                    </Card>
                </GridItem>

                <GridItem xs={12} lg={1} className={classes.orBlock}>
                    <h4>- OR -</h4>
                </GridItem>

                <GridItem xs={12} lg={3} style={{display: "flex", alignItems: "stretch"}}>
                    <Card style={{padding: "10px 16px", justifyContent: "center"}}>
                        <h4>BYPASS the bin:</h4>
                        <div className={classes.center+" "+classes.btnContainer}>
                            {/*<Button color="warning"*/}
                            {/*        className={classes.btnStyle}*/}
                            {/*        onClick={handleBypass}*/}
                            {/*>Ship PO Now</Button>*/}
                            <Button color="warning"
                                    className={classes.btnStyle}
                                    onClick={handlePartial}
                            >Ship Item Now</Button>
                        </div>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                    <Muted>
                        <p>After scanning the bin label you will be directed to place the item and the bin label in the scanned bin to wait for the other items in the order. Once all the items on the purchase order are scanned you will be directed to gather the items for shipment. To bypass the bin, please select one of the options to the right.</p>
                    </Muted>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.cancelLinkCont}>
                        <Link onClick={handleCancel} to="#">
                            Cancel and Return
                        </Link>
                    </div>
                </GridItem>
            </GridContainer>
        );
    else  if (props.allStates.preliminary?.splitOrderStatus?.status == 3)
        return (
            <GridContainer justifyContent="center">
                {/*         <pre>*/}
                {/*    <code>*/}
                {/*      {JSON.stringify(binStatus)}*/}
                {/*    </code>*/}
                {/*</pre>*/}
                {/*{console.log(props.allStates.preliminary?.splitOrderStatus?.status)}*/}
                {binStatus.binStatus == 'not_full' ? (
                    <GridItem xs={12} sm={10}>
                        <h4 className={classes.warningText} style={{textAlign: "left"}}> This item is associated with BIN {binStatus.bin.location_name}</h4>
                    </GridItem>
                ) : (
                    <GridItem xs={12} sm={10}>
                        <h4 className={classes.warningText} style={{textAlign: "left"}}>
                            Last of Split Order for BIN {binStatus?.bin?.location_name}.
                            Please retrieve the items and ship them with this item.
                            Click the submit button to retrieve the shipping label.
                        </h4>
                    </GridItem>
                )}
                <GridItem xs={1} sm={1}/>
                <GridItem xs={5} sm={5}>
                    <h4>Items now in Bin</h4>
                    {renderItemsInBin()}
                </GridItem>
                <GridItem xs={6} sm={6}>
                    <h4>Remaining Items</h4>
                    {renderRemaining()}
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                    <form onSubmit={handleBinAdditionFinish}>
                        <div className={classes.center}>
                            <Button className={classes.submitButton}
                                    autoFocus
                                    type="submit"
                                    color="primary"
                            >Submit</Button>
                        </div>
                    </form>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={10}>
                    <br />
                    <Muted>
                        <p>Once all the items on the purchase order are scanned you will be directed to gather the items from the shipment</p>
                    </Muted>
                </GridItem>
                <br />
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.cancelLinkCont}>
                        <Link onClick={handleCancel} to="#">
                            Cancel and Return
                        </Link>
                    </div>
                </GridItem>
            </GridContainer>
        )
    else
        return (
            <pre>
              <code>
                {JSON.stringify(props)}
              </code>
          </pre>
        )
});

Step2.displayName = "Step2";

export default Step2;