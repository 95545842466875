import React, {useEffect, useMemo, useState} from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
  useRowSelect,
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
import {Link} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Button from "components/CustomButtons/Button.js";

// core styles
import buttonStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

// app services
import OmsJobService from "../../services/OmsJobService";
import PurchaseOrderService from "../../services/PurchaseOrderService";

import LoadingSpinner from "app_components/LoadingSpinner";


const useButtonStyles = makeStyles(buttonStyles);

const newStyles = {
  ...styles,
  ... customSelectStyle,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
  btn: {
    background: "#33CCCC"
  },
  selectionActions: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  }
};

const useStyles = makeStyles(newStyles);

// Define a default UI for filtering
function DefaultColumnFilter({
                               column: { filterValue, preFilteredRows, setFilter },
                             }) {
  const count = preFilteredRows.length;

  return (
      <CustomInput
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: filterValue || "",
            onChange: (e) => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            },
            placeholder: `Search ${count} records...`,
          }}
      />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table(props) {
    const buttonClasses = useButtonStyles();

    const {
      columns,
      data,
      canPreviousPage,
      canNextPage,
      pageNumber,
      setPage,
      numberOfPages,
      setNumberOfRows,
      pageSize,
      poCount,
      customSetSortBy,
      selectAll,
      deselectAll,
      checked,
      loading,
      initialSortBy,
      initialSortOrder
  } = props

  const [notificationMessage, setNotificationMessage] = useState('Placeholder Message.')
  const [notificationType, setNotificationType] = useState('success')
  const [showNotification, setShowNotification] = useState(false)
  const [packingListsLoading, setPackingListsLoading] = useState(false)
  const classes = useStyles();
  const filterTypes = useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true;
          });
        },
      }),
      []
  );

  const kickoffPackingSlipGeneration = async () => {
      setPackingListsLoading(true)
      new OmsJobService()
          .kickoffPackingListGenerationJob(checked)
          .then(data => {
              if(data['status'] == 'success'){
                  setNotificationType('success')
                  setNotificationMessage("Bulk packing slip is being generated")
                  deselectAll()
              } else {
                  setNotificationType('danger')
                  setNotificationMessage(data.message)
              }
              setShowNotification(true)
              setPackingListsLoading(false)
          })
          .catch((error) => {
              console.log(error);
          });
  }

  const printPOs = () => {
      new PurchaseOrderService()
          .printPOs(checked)
          .then(data => {
              console.log(data);
              const file = new Blob([data], { type: "application/pdf"})
              const fileURL = URL.createObjectURL(file)
              const pdfWindow = window.open()
              pdfWindow.location.href = fileURL
          })
          .catch((error) => {
              console.log(error);
          });
  }

  const requestPackingSlip = () => {
      kickoffPackingSlipGeneration();
  }

  const defaultColumn = useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    pageIndex,
    gotoPage,
  } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: pageSize, pageIndex: page, sortBy: [{id: initialSortBy, desc: initialSortOrder === 'desc'}]},
        manualPagination: true,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        disableFilters: true,
        disableMultiSort: true,
        manualSortBy: true,
      },
      useFilters, // useFilters!
      useSortBy,
      usePagination,
  );
    useEffect(() => {
        customSetSortBy(state.sortBy);
    }, [state.sortBy]);

  let pageSelectData = Array.apply(
      null,
      Array(numberOfPages)
  ).map(function () {});
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];
  if(loading)
      return (
          <>
              <br/><br/>
              <LoadingSpinner loading={true}/>
              <br/><br/>
          </>
      )
  return (
      <>
        <Snackbar
            place="tr"
            color={notificationType}
            message={notificationMessage}
            open={showNotification}
            closeNotification={() => setShowNotification(false)}
            close
        />
        <LoadingSpinner loading={packingListsLoading} style={{top: "50px"}}/>
        <div className="ReactTable -striped -highlight">
          <div className="pagination-top">
            <div className="-pagination">
              <div className="-previous">
                <button
                    type="button"
                    onClick={() => {
                        setPage(pageNumber - 1);
                    }}
                    disabled={!canPreviousPage()}
                    className="-btn"
                >
                  Previous
                </button>
              </div>
              <div className="-center">
                <GridContainer className={classes.gridContainer}>
                  <GridItem xs={12} sm={6} md={4}>
                    <FormControl
                        fullWidth
                        className={
                          classes.selectFormControl +
                          " " +
                          classes.formControlMargins
                        }
                    >
                      <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={pageNumber-1}
                          onChange={(event) => {
                            setPage(event.target.value+1);
                          }}
                          inputProps={{
                            name: "pageSelect",
                            id: "page-select",
                          }}
                      >
                        {pageSelectData.map((prop, key) => {
                          return (
                              <MenuItem
                                  key={key}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={key}
                              >
                                Page {key + 1}
                              </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <FormControl
                        fullWidth
                        className={
                          classes.selectFormControl +
                          " " +
                          classes.formControlMargins
                        }
                    >
                      <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={pageSize}
                          onChange={(event) => {
                            setNumberOfRows(event.target.value);
                          }}
                          inputProps={{
                            name: "numberOfRows",
                            id: "number-of-rows",
                          }}
                      >
                        {numberOfRowsData.map((prop) => {
                          return (
                              <MenuItem
                                  key={prop}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={prop}
                              >
                                {prop} rows
                              </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </div>
              <div className="-next">
                <button
                    type="button"
                    onClick={() => {
                        setPage(pageNumber + 1)
                    }}
                    disabled={!canNextPage()}
                    className="-btn"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                      size="sm"
                      className={buttonClasses.marginRight + " " + classes.btn}
                      onClick={requestPackingSlip}
                      style={{float: "right"}}
                    >Packing Lists For Selected</Button>
                    <div className={classes.selectionActions}>
                      <span><b>Count:</b> {poCount}</span>&nbsp;
                      <Link onClick={selectAll} to='#'>
                        Select All
                      </Link>&nbsp;| &nbsp;
                      <Link onClick={deselectAll} to='#'>
                        Deselect All
                      </Link>
                    </div>
                </GridItem>
            </GridContainer>



          <table {...getTableProps()} className="rt-table">
            <thead className="rt-thead -header" >
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                  {headerGroup.headers.map((column, key) => (
                      <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={!column.canSort ?
                              classnames("rt-th rt-resizable-header")
                          :
                              classnames("rt-th rt-resizable-header", {
                                  "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                                  "-sort-asc": column.isSorted && !column.isSortedDesc,
                                  "-sort-desc": column.isSorted && column.isSortedDesc,
                              })
                          }



                      >
                        <div className="rt-resizable-header-content">
                          {column.render("Header")}
                        </div>
                        {/* Render the columns filter UI */}
                        <div>
                          {headerGroup.headers.length - 1 === key
                              ? null
                              : column.canFilter
                                  ? column.render("Filter")
                                  : null}
                        </div>
                      </th>
                  ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                  <tr
                      {...row.getRowProps()}
                      className={classnames(
                          "rt-tr",
                          { " -odd": i % 2 === 0 },
                          { " -even": i % 2 === 1 }
                      )}
                  >
                    {row.cells.map((cell) => {
                      return (
                          <td {...cell.getCellProps()} className="rt-td">
                            {cell.render("Cell")}
                          </td>
                      );
                    })}
                  </tr>
              );
            })}
            </tbody>
          </table>
          <div className="pagination-bottom"></div>
        </div>
      </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
