import React, {useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {makeStyles} from "@material-ui/core/styles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";


const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center",
    },
    cardCategory: {
        margin: "0",
        color: "#999999",
    },
    wizardHeader: {
        textAlign: "center",
        padding: "25px 0 35px",
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    alignLeft: {
        fontWeight: "300",
        textAlign: "left"
    },
    center: {
        textAlign: "center",
    },
    inputCol: {
        position: "relative",
        float: "left",
        width: "85%"
    },
};
//todo organize imports
const useStyles = makeStyles(styles);
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";

import PrintStationService from "services/PrintStationService";
import ErrorService from "../../services/ErrorService";

export default function PrintUpcPanel() {
    const classes = useStyles();
    const [poid, setPoid] = React.useState("");

    const getPackingListURL = async () => {
        console.log('OFFICIAL: getUPC');

        new PrintStationService().getPackingListURL(poid)
            .then((data) => {
                console.log('back from ship_po');
                console.log(data);
                if(data.status == 'error')
                    // alert(data.message)
                    new ErrorService().report(data)
                else {
                    printPackingList(data.packing_list_url);
                    setPoid('');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const printPackingList = (data) => {
        console.log(data);
        window.open(data, "_blank")
    }


    const handleSubmit = (e)=> {
        e.preventDefault();
        getPackingListURL();
    }

    return (
        <Card>
            <CardHeader className={classes.infoText}>
                <h3 >
                    Print Packing List
                </h3>
            </CardHeader>
            <CardBody>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={10}>
                        <h4 className={classes.alignLeft}>
                            Please scan the purchase order BARCODE from the packing list, pickticket, or screen:
                        </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={10} >
                        <form onSubmit={handleSubmit} >
                            <div className={classes.inputCol}>
                                <CustomInput
                                    labelText={
                                        <span>
                                      Purchase Order Barcode
                                    </span>
                                    }
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: poid,
                                        onChange: (event) => {
                                            setPoid(event.target.value);
                                        },
                                    }}
                                />
                            </div>
                            <div className={classes.center}>
                                <Button type="submit"
                                        color="primary"
                                >Submit</Button>
                            </div>
                            <br /><br />
                            <Muted>
                                <p>Scan the purchase order barcode to pull up the packing list in a new window.</p>
                            </Muted>
                        </form>
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>

    )
}