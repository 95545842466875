import React, {useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
// import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import {infoColor} from "assets/jss/material-dashboard-pro-react";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Muted from "components/Typography/Muted.js";

// app services
import PrintStationService from "services/PrintStationService";
import PrinterService from "services/PrinterService";
import ErrorService from "../../services/ErrorService";
import {Link} from "react-router-dom";

const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    warningText: {
        color: infoColor,
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    inputAdornmentIcon: {
        color: "#555",
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px",
    },
    center: {
        textAlign: "center",
    },
    cancelLinkCont: {
        position: "relative",
        float: "right",
        padding: "20px",
        color: "#33CCCC"
    },
  ...customSelectStyle,
};
// todo custom style def for this pane? maybe use that
const useStyles = makeStyles(styles);

const LabelStep4 = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const [simpleSelect, setsimpleSelect] = React.useState("");
    const callHome = props.callHome;

    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
          return isValidated();
        },
        sendState: () => {
          console.log('step4 sendState');
          return sendState();
        },
        resetState: () => {
            console.log('step4 resetState');
        },
        state: {
          simpleSelect,
        },
    }));

    const sendState = () => {
        return {
          // simpleSelect,
        };
    };

    const isValidated = () => {
        return true;
    };

    const printShippingLabel = (data) => {
        new PrinterService().printShippingLabel(data)
    }

    const shipPO = async (flag) => {
        const addFlag = flag ? 'add_label=true' : 'add_label=false'
        new PrintStationService().shipPO(props.allStates.preliminary.poiid, addFlag)
            .then((data) => {
                if(data.status == 'error') {
                    new ErrorService().report(data)
                    callHome('step4','error');
                }

                printShippingLabel(data);
                callHome('step4','label_printed');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePrint = (addLabel) => {
        shipPO(addLabel);
    }

    const handleCancel = ()=> {
        callHome('step2','cancel');
    }

    return (
        <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12}>
                <h4 className={classes.warningText}>Shipping Label has already been printed</h4>
                <GridContainer>
                    <GridItem xs={6} sm={6}>
                        <div className={classes.center}>
                            <Button color="primary"
                                    onClick={() => handlePrint(true)}
                                    style={{width: '200px'}}
                            >Print an Additional Label</Button>
                        </div>
                        <br/>
                        <div className={classes.center}>
                            Another label with the same information but a unique tracking number will be generated.
                            <br/>
                            <Muted>
                           <br/>Use if purchase order needed an additional box
                            </Muted>
                            </div>
                    </GridItem>
                    <GridItem xs={6} sm={6}>
                        <div className={classes.center}>
                            <Button color="primary"
                                    style={{width: '200px'}} //todo switch to class
                                    onClick={() => handlePrint(false)}
                            >Print Again</Button>
                        </div>
                        <br />
                        <div className={classes.center}>
                            All labels will be printed again.<br/>
                            <Muted>
                                <br/> Use if a label was damaged or misapplied
                            </Muted>
                        </div>
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className={classes.cancelLinkCont}>
                    <Link to="#" onClick={handleCancel}>
                    Cancel and Return
                    </Link>
                </div>
            </GridItem>
        </GridContainer>
    );
});

LabelStep4.displayName = "Step4";

export default LabelStep4;
