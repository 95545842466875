// base APIS
export const BASE_AUTH_API = '/api/sessions'
export const BASE_PASSWORD_API = '/api/passwords'
export const BASE_PURCHASE_ORDER_API = '/api/v1/purchase_orders';
export const BASE_CUSTOMER_API = '/api/v1/customers';
export const BASE_BIN_LOCATIONS_API = '/api/v1/bin_locations';
export const BASE_CUTLIST_API = '/api/v1/cutlist';
export const BASE_BULK_PACKING_SLIP_API = '/api/v1/bulk_packing_slips'
export const BASE_PRINT_STATION_API = '/api/v1/print_station';
export const BASE_SHIPPING_MANIFESTS_API = '/api/v1/shipping_manifests'
export const BASE_OMS_JOB_API = '/api/v1/oms_jobs'

export const BASE_DASHBOARD_API = '/api/v1/dashboard';