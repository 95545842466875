import request from 'services/Service.js';
import { BASE_SHIPPING_MANIFESTS_API } from 'variables/api_urls';

export default class ShippingManifestsService {
  constructor() {
    this.resource_url = BASE_SHIPPING_MANIFESTS_API;
  }

  search(query) {
    return request({
      method: 'get',
      url: `${this.resource_url}?page=${query["page"]}&per_page=${query["per_page"]}`
    })
  }

  createManifest(carrier) {
    return request({
      method: 'post',
      url: `${this.resource_url}?carrier=${carrier}`
    })
  }
}