import React, {useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import Gavel from "@material-ui/icons/Gavel";
import Assignment from "@material-ui/icons/Assignment";
import HelpOutline from "@material-ui/icons/HelpOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";


import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import PrintLabelPanel from "../../app_components/PrintStation/PrintLabelPanel";
import PrintUpcPanel from "../../app_components/PrintStation/PrintUpcPanel";
import PrintPackingListPanel from "../../app_components/PrintStation/PrintPackingListPanel";
import { launchQZ } from "../../app_components/PrintStation/QzPrint";
import QzPrintService from "services/QzPrintService";
import { getDymoPrinter } from "../../app_components/PrintStation/DymoPrint";


const styles = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
  navPills: {
    "& button": {
        background: "green"
    },
    "& button::hover": {
          background: "#33CCCC"
      }
  }
};

const useStyles = makeStyles(styles);

export default function PrintLabels() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [qzLaunched, setQzLaunched] = useState(false);
  const [dymoLaunched, setDymoLaunched] = useState(false);
  const SHIPPING_LABEL_TAB = 0;
  const UPC_TAB = 1;

  useEffect(()=> {
      printerSetup(SHIPPING_LABEL_TAB);  // launch QZ on initial load
  }, [])

  const printerSetup = (activeTab)=> {
      switch(activeTab) {
          case SHIPPING_LABEL_TAB:
              if(!qzLaunched){
                  initializeQZ();
                  setQzLaunched(true);
              }
              break;
          case UPC_TAB:
              if(!dymoLaunched){
                  initializeDymo();
                  setDymoLaunched(true);
              }
              break;
      }

  }

  const initializeQZ = ()=> {
      //alert("set up qz code")
      new QzPrintService().setupPrintRequest();
      launchQZ();
   }

  const initializeDymo = ()=> {
      //alert("set up dymo code")
      getDymoPrinter();
  }

  return (
    <>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={10}>
          <h3 className={classes.pageSubcategoriesTitle}>Print Station</h3>
          <NavPills
              color="warning"
              alignCenter
              onChange={printerSetup}
              tabs={[
                {
                  tabButton: "Shipping Labels",
                  // tabIcon: Info,
                  tabContent: (
                      <PrintLabelPanel />
                  ),
                },
                {
                  tabButton: "Product UPC",
                  // tabIcon: Assignment,
                  tabContent: (
                      <PrintUpcPanel activeTab={activeTab} />
                  ),
                },
                {
                  tabButton: "Packing List",
                  // tabIcon: Gavel,
                  tabContent: (
                      <PrintPackingListPanel />
                  ),
                }
              ]}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
