import React, {useEffect} from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
import {navigate} from "react-big-calendar/lib/utils/constants";
import {endOf} from "react-big-calendar/lib/utils/dates";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    var width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }

    this.resetSelf = props.resetWizard;

    this.state = {
      keyBase: 0,
      currentStep: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s",
      },
      // currentStateCounter: 0,
      allStates: {},
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
    this.callHome = this.callHome.bind(this);
    this.assessState = this.assessState.bind(this);
    this.resetState = this.resetState.bind(this);
    // this.resetWizard = this.resetWizard.bind(this);
  }
  wizard = React.createRef();
  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth);
    // this.appendScript("/shipping/jquery-3.6.0.min.js")
    // this.appendScript("/shipping/qz-tray/qz-tray.js")
    // this.appendScript("/shipping/qz-tray/qz-print.js")
    // this.appendScript("/shipping/qz-tray/dependencies/rsvp-3.1.0.min.js")
    // this.appendScript("/shipping/qz-tray/dependencies/sha-256.min.js")
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  appendScript(scriptToAppend) {
    console.log('appending script')
    const scriptx = document.createElement("script");
    scriptx.src = scriptToAppend;
    scriptx.async = true;
    document.body.appendChild(scriptx);
  }

  resetState() {
    console.log("Re-setting state")
    for (var i = 0; i < this.props.steps.length; i++) {
      if (this[this.props.steps[i].stepId].resetState !== undefined) {
        this[this.props.steps[i].stepId].resetState();
      }
    }
    this.setState({ allStates: {}})
  }

  assessState(step, message) {
   console.log("Assessing State");
   console.log("----------------");
   console.log("Step: "+step);
   console.log("Message: "+message);

   switch (step) {
     case 'step1':
       //todo consider changing this next switch to drive from some status other than message
       switch(message) {
         case 'label_printed':
           console.log('step1 Label printed - will reset now');
           this.resetState();
           break;
         case 'label_exists':
           console.log('label_exists');
           console.log(this.state.allStates)
           this.navigationStepChange(3);
           break
         case 'split_order_status':
           this.navigationStepChange(1);
           break;
         case 'manual':
           console.log('manual shipment');
           console.log(this.state.allStates)
           this.navigationStepChange(2);
           break;
         default:
           console.log('UNCAUGHT message: *'+ message+ '*');
           this.navigationStepChange(1);
           break;
       }

       break;
     case 'step2':
       switch(message) {
         case 'start_partial':
           this.navigationStepChange(2);
           break;
         default:
           this.navigationStepChange(0);
           this.resetState();
           break;
       }
        break;

     case 'step3':
       this.navigationStepChange(0);
       this.resetState();
     break;

     case 'step4':
      switch(message) {
        case 'label_printed':
        console.log('Step4 label printed - will reset now');

        this.navigationStepChange(0); // resetState must happen AFTER nav change
        this.resetState();
        break;

        case 'error':
          console.log('Step4 error');

          this.navigationStepChange(0); // resetState must happen AFTER nav change
          this.resetState();
          break;
      }
     default:
       this.navigationStepChange(0);
       this.resetState();
   }
  }

  callHome (step, msg) {
    this.assessState(step, msg);
  }

  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }

  navigationStepChange(key) {
    if(key===0)
      this.props.resetWizard();

    const newAllStates = {...this.state.allStates};

    if (this.props.steps) {
      var validationState = true;
      if (key > this.state.currentStep) {
        for (var i = 0; i < this.props.steps.length; i++) {
          if (this[this.props.steps[i].stepId].sendState !== undefined) {
            newAllStates[this.props.steps[i].stepId] = this[this.props.steps[i].stepId].sendState();
          }
        }
      }


      this.setState({currentStep:key, allStates: newAllStates});

      this.refreshAnimation(key);
    }
  }

  nextButtonClick() {
    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {
      if (
        this[this.props.steps[this.state.currentStep].stepId].sendState !==
        undefined
      ) {
        this.setState({
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState(),
          },
        });
      }
      var key = this.state.currentStep + 1;
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
      });
      this.refreshAnimation(key);
    }
  }
  previousButtonClick() {
    if (
      this[this.props.steps[this.state.currentStep].stepId].sendState !==
      undefined
    ) {
      this.setState({
        allStates: {
          ...this.state.allStates,
          [this.props.steps[this.state.currentStep].stepId]: this[
            this.props.steps[this.state.currentStep].stepId
          ].sendState(),
        },
      });
    }
    var key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
      });
      this.refreshAnimation(key);
    }
  }
  finishButtonClick() {
    if (
      (this.props.validate === false &&
        this.props.finishButtonClick !== undefined) ||
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined) &&
        this.props.finishButtonClick !== undefined)
    ) {
      this.setState(
        {
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState(),
          },
        },
        () => {
          this.props.finishButtonClick(this.state.allStates);
        }
      );
    }
  }

  refreshAnimation(index) {
    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance =
      this.wizard.current.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.wizard.current.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + "%" });

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)",
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }
  render() {
    const { classes, title, subtitle, color, steps } = this.props;
    return (
      <div className={classes.wizardContainer} ref={this.wizard}>
        {/*        <pre>*/}
        {/*       <code>*/}
        {/*         {JSON.stringify(this.state)}*/}
        {/*       </code>*/}
        {/*</pre>*/}
        <Card className={classes.card}>
          <div className={classes.wizardHeader}>
            <h3 className={classes.title}>{title}</h3>
            <h5 className={classes.subtitle}>{subtitle}</h5>
          </div>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key + this.state.keyBase}
                    style={{ width: this.state.width }}
                  >
                    <a
                      href="#pablo"
                      className={classes.stepsAnchor}
                      onClick={(e) => {
                        e.preventDefault();
                        // alert(key);
                        // this.navigationStepChange(key);
                      }}
                    >
                      {prop.stepName}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div
              className={classes.movingTab + " " + classes[color]}
              style={this.state.movingTabStyle}
            >
              {steps[this.state.currentStep].stepName}
            </div>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = cx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key,
              });
              return (
                <div className={stepContentClasses} key={key}>
                  <prop.stepComponent
                    key={key+this.state.keyBase}
                    callHome={this.callHome}
                    ref={(node) => (this[prop.stepId] = node)}
                    allStates={this.state.allStates}
                    currentStep={this.state.currentStep}
                    // currentStateCounter={this.state.currentStateCounter}
                  />
                </div>
              );
            })}
          </div>
          {/*<div className={classes.footer}>*/}
          {/*  <div className={classes.left}>*/}
          {/*    {this.state.previousButton ? (*/}
          {/*      <Button*/}
          {/*        className={this.props.previousButtonClasses}*/}
          {/*        onClick={() => this.previousButtonClick()}*/}
          {/*      >*/}
          {/*        {this.props.previousButtonText}*/}
          {/*      </Button>*/}
          {/*    ) : null}*/}
          {/*  </div>*/}
          {/*  <div className={classes.right}>*/}
          {/*    {this.state.nextButton ? (*/}
          {/*      <Button*/}
          {/*        color="rose"*/}
          {/*        className={this.props.nextButtonClasses}*/}
          {/*        onClick={() => this.nextButtonClick()}*/}
          {/*      >*/}
          {/*        {this.props.nextButtonText}*/}
          {/*      </Button>*/}
          {/*    ) : null}*/}
          {/*    {this.state.finishButton ? (*/}
          {/*      <Button*/}
          {/*        color="rose"*/}
          {/*        className={this.finishButtonClasses}*/}
          {/*        onClick={() => this.finishButtonClick()}*/}
          {/*      >*/}
          {/*        {this.props.finishButtonText}*/}
          {/*      </Button>*/}
          {/*    ) : null}*/}
          {/*  </div>*/}
          {/*  <div className={classes.clearfix} />*/}
          {/*</div>*/}


        </Card>



      </div>
    );
  }
}

Wizard.defaultProps = {
  color: "rose",
  title: "Here should go your title",
  subtitle: "And this would be your subtitle",
  previousButtonText: "Previous",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Next",
  finishButtonClasses: "",
  finishButtonText: "Finish",
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.object.isRequired,
      stepId: PropTypes.string.isRequired,
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool,
};

export default withStyles(wizardStyle)(Wizard);
