import request from 'services/Service.js';
import { BASE_CUSTOMER_API } from 'variables/api_urls';

export default class CustomerService {
  constructor() {
    this.search_endpoint = `${BASE_CUSTOMER_API}/select_options`;
  }

  select_options(query_string) {
    let parameters = (query_string !== undefined) ? query_string : ''
    return request({
      method: 'get',
      url: `${this.search_endpoint}${parameters}`
    })
  }
}
