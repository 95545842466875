import React from "react"
import {makeStyles} from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

function SimpleCheckboxForTable({list, handleToggle, listItem}) {
  const classes = useStyles();

  const isItemSelected = () => {
    return list.includes(listItem)
  }

  return (
    <Checkbox
      checked={isItemSelected()}
      tabIndex={-1}
      onClick={() => handleToggle(listItem)}
      checkedIcon={
        <Check className={classes.checkedIcon}/>
      }
      icon={<Check className={classes.uncheckedIcon}/>}
      classes={{
        checked: classes.checked,
        root: classes.checkRoot,
      }}
    />
  )
}

export default SimpleCheckboxForTable