import React, {useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import endOfDayStyles from "assets/jss/app_components_styles/endOfDay.js";

// core components
import ShippingManifestsService from "services/ShippingManifestsService";
import OmsJobService from "services/OmsJobService";
import Button from "components/CustomButtons/Button.js";
import OpenInNew from "@material-ui/icons/OpenInNew";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import NavPills from "components/NavPills/NavPills.js";
import ManifestForm from "app_components/ScanForms/ManifestForm";
import FedexLabels from "app_components/ScanForms/FedexLabels";
import Snackbar from "components/Snackbar/Snackbar.js";
import LoadingSpinner from "app_components/LoadingSpinner.js";
import LocalShipping from "@material-ui/icons/LocalShipping";

const useStyles = makeStyles(endOfDayStyles);

export default function EndOfDay() {
  const classes = useStyles();
  const [previousManifests, setPreviousManifests] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSizeState] = useState(JSON.parse(localStorage.getItem('number_of_rows_for_eod')) || 10)
  const [pageTotal, setPageTotal] = useState(1)
  const [selectedCarrier, setSelectedCarrier] = useState('')
  const [manifestError, setManifestError] = useState('')
  const [showNotification, setShowNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('Placeholder Message.')
  const [notificationType, setNotificationType] = useState('success')
  const [regenDone, setRegenDone] = useState(false)
  const [loading, setLoading] = useState(false)

  const setPageSize = (rows) => {
    localStorage.setItem('number_of_rows_for_eod', JSON.stringify(rows))
    setPageSizeState(rows)
  }

  const paginationProps = {
    page,
    pageSize,
    pageTotal,
    setPage,
    setPageSize
  }

  let shippingManifestsService = new ShippingManifestsService()
  let omsJobService = new OmsJobService()

  const getPreviousManifests = async (params) => {
    console.log('OFFICIAL: getPreviousManifests');
    shippingManifestsService.search(params)
      .then((data) => {
        console.log("Setting data");

        setPreviousManifests(data['previous_manifests'])
        setPageTotal(data['total_pages'])
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(async () => {
    getPreviousManifests(
      {
        page: page,
        per_page: pageSize
      }
    )
  },[page, pageSize])

  const formattedManifests = () => {
    return previousManifests.map(manifest => formatManifestForTable(manifest))
  }

  const formatManifestForTable = (manifest) => {
    let {url, ...result} = {...manifest} // Clone object instead of mutating it
    if(manifest.url) {
      result.open_button = <Button color="primary" className={classes.actionButton} href={manifest.url} target="_blank">
        <OpenInNew />
      </Button>
    } else {
      result.open_button = ''
    }
    return Object.values(result)
  }

  const submitManifest = async () => {
    if(!selectedCarrier || selectedCarrier.length === 0){
      setManifestError("Carrier must be selected before submission.")
      return
    }
    shippingManifestsService
      .createManifest(selectedCarrier)
      .then(data => {
        let notificationType = data['result'] == 'success' ? 'success' : 'danger'
        setNotificationType(notificationType)
        setNotificationMessage(data['message'])
        setLoading(false)
        setShowNotification(true)

        // if("manifest_urls" in data){
        //   let urls = data["manifest_urls"]
        //   urls.forEach((url) => {
        //     window.open(url, "_blank")
        //   })
        //   setManifestError('')
        // } else {
        //   setManifestError(data["error"])
        // }
      })
  }

  const kickoffRegenerateFedexLabelJob = async () => {
    setLoading(true)
    omsJobService
      .kickoffRegenerateFedexLabelsJob()
      .then(data => {
        let notificationType = data['status'] == 'success' ? 'success' : 'danger'
        if (data['status'] == 'success')  setRegenDone(true)
        setNotificationType(notificationType)
        setNotificationMessage(data["message"])
        setLoading(false)
        setShowNotification(true)
      })
  }

  return (
    <>
      <Snackbar
        place="tr"
        color={notificationType}
        message={notificationMessage}
        open={showNotification}
        closeNotification={() => setShowNotification(false)}
        close
      />
      <h3 className={classes.pageTitle}>End-Of-Day Shipping</h3>
      <LoadingSpinner loading={loading} />
      <div className={classes.endOfDayContainer}>
        <GridContainer>
          <GridItem xs={12}>
                <h4 className={classes.cardIconTitle}></h4>
                <NavPills
                  color="warning"
                  alignCenter
                  tabs={[
                    {
                      tabButton: "DHL Manifest",
                      tabContent: <ManifestForm
                        manifests={formattedManifests()}
                        paginationProps={paginationProps}
                        setSelectedCarrier={setSelectedCarrier}
                        submitManifest={submitManifest}
                        error={manifestError}
                      />
                    },
                    {
                      tabButton: "FedEx Labels",
                      tabContent: <FedexLabels kickoffRegeneration={kickoffRegenerateFedexLabelJob} regenDone={regenDone}/>
                    },
                  ]}
                  onChange={() => {
                    document.querySelector('div[aria-hidden="true"] .toggle-visibility').style.display = 'block'
                    document.querySelector('div[aria-hidden="false"] .toggle-visibility').style.display = 'none'
                  }}
                  />
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
}
