/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

const newStyles = {
  loadingSpinner: {
    margin: "3px 0 !important",
    width: "50px",
    height: "50px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "10",
    '& div': {
      backgroundColor: "#33CCCC",
      borderRadius: "10px",
      animation: `$rotation 3s infinite linear`,
      height: '100%'
    }
  },
  "@keyframes rotation": {
    "from": {
      transform: "rotate(0deg)"
    },
    "to": {
      transform: "rotate(360deg)"
    }
  }
};

const useStyles = makeStyles(newStyles);

// Our table component
/* Due to absolute positioning the location
   of the spinner is relative to the closest
   parent element that has 'relative' position.
   So if you want this to be centered on an
   element containing the spinner, give it
   position: relative.
 */
function LoadingSpinner({ loading, style= {} }) {

  const classes = useStyles();

  if(loading !== true){
    return null // render nothing if prop provider is not "loading"
  }

  return (
    <div className={classes.loadingSpinner} style={style}>
      <div>

      </div>
    </div>
  )
}

export default LoadingSpinner;
