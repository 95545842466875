import React, {useState, useEffect} from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import {Spinner, Dropdown} from 'react-bootstrap'
// import Dropdown from 'react-bootstrap/Dropdown'
import {Link} from "react-router-dom";


const Orders = () => {

    // constructor(props)
    // {
    //     super(props);
    //     this.state = {
    //         orders: []
    //     };
    // }
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [loading,setLoading] = useState(false);
    const [totalCount,setTotalCount] = useState(0);
    const [perPage,setPerPage] = useState(10);
    const [currentPage,setCurrentPage] = useState(1);
    const getPurchaseOrderData = async (params) => {

        try {
            const data = await axios.get("/api/v1/purchase_orders/index", { params: params});
            setPurchaseOrders(data.data['purchase_orders']);
            setTotalCount(data.data['total_size'])
            setLoading(true);

        } catch(e) {
            console.log(e);
        }
    }
    const columns = [
        { dataField: "id", text: "ID"},
        { dataField: "customer_po", text: "Purchase Order Number" },
        { dataField: "customer_name", text: "Customer Name", sort: true },
        { dataField: "order_date", text: "Order Date", sort: true },
        { dataField: "status_name", text: "Current Status", sort: true},

    ]

    useEffect(() => {
        getPurchaseOrderData({
            page: 1,
            per_page: perPage
        });
    }, [])

    const onTableChange = (type, newState) => {
        console.log('Table Change');
        console.log(type);
        console.log(newState);
        switch (type) {
            case 'pagination':
                setPerPage(newState['sizePerPage']);
                setCurrentPage((newState['page']))
                getPurchaseOrderData({
                    page: newState['page'],
                    per_page: newState['sizePerPage'],
                    sort_by: newState['sortField'],
                    sort_order: newState['sortOrder']
                });
                break;
            case 'sort':
                setCurrentPage(1);
                setPerPage(newState['sizePerPage']);
                getPurchaseOrderData({
                    page: 1,
                    per_page: newState['sizePerPage'],
                    sort_by: newState['sortField'],
                    sort_order: newState['sortOrder']
                });
                break;
            default:
                return;
        }
    }

    return <div className="Orders">
        <>
            <section className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5">
                    <h1 className="display-4">Purchase Orders</h1>
                    <p className="lead text-muted">
                        Purchase Order listing page
                    </p>
                </div>
            </section>

            <div className="">
                <main className="container">
                    {/*<Dropdown>*/}
                    {/*    <Dropdown.Toggle variant="success" id="dropdown-basic">*/}
                    {/*        Dropdown Button*/}
                    {/*    </Dropdown.Toggle>*/}

                    {/*    <Dropdown.Menu>*/}
                    {/*        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>*/}
                    {/*        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>*/}
                    {/*        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>*/}
                    {/*    </Dropdown.Menu>*/}
                    {/*</Dropdown>*/}
                    {
                        loading ? (
                            <BootstrapTable
                                remote
                                keyField="id"
                                data={purchaseOrders}
                                columns={columns}
                                onTableChange={onTableChange}
                                pagination={paginationFactory({totalSize: totalCount, sizePerPage: perPage, page: currentPage})}/>
                        ) : (
                            // <h1>Waiting</h1>
                            <Spinner annimation="border" />
                        )
                    }
                    <Link to="/" className="btn btn-link">
                        Home
                    </Link>
                </main>
            </div>
        </>
    </div>;

}
export default Orders;
