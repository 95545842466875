import React, {useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import ContactMail from "@material-ui/icons/ContactMail";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import OmsJobService from "services/OmsJobService";
import LoadingSpinner from "app_components/LoadingSpinner.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import PurchaseOrderService from "services/PurchaseOrderService";
import {Link} from "react-router-dom";
import Print from "@material-ui/icons/Print";

const customStyles = {
  ...styles,
  note: {
    borderBottom: "1px solid #eee",
    marginTop: "12px",
    '&:last-child': {
      borderBottom: "none",
    },
    '& p:first-child:first-letter': {
      textTransform: "uppercase",
    }
  },
  poItemGridContainer: {
    display: "grid",
    position: "relative",
    gridTemplateColumns: "2fr 2fr 1fr 1fr 2fr",
    gridTemplateRows: "auto auto",
    gridTemplateAreas: "'itemcode mfgcode status qty shipmentinfo' 'artthumbnail artthumbnail qrcode qrcode productupc'",
    marginBottom: "32px",
    '& > div': {
      border: "1px solid #eee",
      padding: "16px 12px 8px 20px",
      position: "relative",
      '& em':{
        fontSize: "12px",
        position: "absolute",
        left: "3px",
        top: "0",
        color: "#777",
      },
      '& img':{
        margin: '10px',
      }
    },
    '& > button': {
      position: "absolute",
      bottom: "5px",
      right: "5px"
    }
  },
  itemCode: {
    gridArea: "itemcode",
  },
  mfgCode: {
    gridArea: "mfgcode",
  },
  status: {
    gridArea: "status",
  },
  qty: {
    gridArea: "qty",
  },
  shipmentInfo: {
    gridArea: "shipmentinfo",
    '& p': {
      marginBottom: "0",
    }
  },
  artThumbnail: {
    gridArea: "artthumbnail",
    '& img': {
      minHeight: "120px",
    },
    '& img::before': {
      content: '" "',
      display: "block",
      position: "absolute",
      height: "120px"
    }
  },
  qrCode: {
    gridArea: "qrcode",
  },
  productUpc: {
    gridArea: "productupc",
  },
  pageTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  poDetailsBody: {
    paddingTop: "0",
    '& > hr': {
      marginTop: "0",
    }
  },
  openBtn: {
    position: "absolute",
    top: "5px",
    right: "50px",
    padding: "5px!important",
    '& svg': {
      margin: "0"
    }
  },
  poDetailsHeader: {
    position: "relative",
    marginBottom: "10px !important",
    '& > span': {
      position: "absolute",
      top: "5px",
      right: "5px",
      color: "#999",
      '& svg': {
        width: "40px",
        height: "40px",
      },
      '&:hover': {
        cursor: "pointer",
      }
    }
  },
  disabledButton: {
    backgroundColor: "#D5D5D5!important",
    boxShadow: "none",
  },
}


const useStyles = makeStyles(customStyles);

export default function PoDetails(props) {
  const purchaseOrderId = props.match.params.id
  const [purchaseOrder, setPurchaseOrder] = React.useState({})
  const [showNotification, setShowNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('Placeholder Message.')
  const [notificationType, setNotificationType] = useState('success')
  const [loading, setLoading] = useState(false)
  const [hidePoDetails, setHidePoDetails] = useState(true)

  const getPurchaseOrderData = async () => {
    console.log('OFFICIAL: getPurchaseOrderData');

    new PurchaseOrderService().retrieve(purchaseOrderId)
        .then((data) => {
          console.log("Setting data");
          console.log(data['purchase_order']);
          setPurchaseOrder(data['purchase_order']);
        })
        .catch((error) => {
          console.log(error);
        });
  }

    const reRipImage = (purchase_order_item_id) => {
      setLoading(true)
      new OmsJobService().reRipImage(purchase_order_item_id).then((data) => {
        let notificationType = data['status'] == 'success' ? 'info' : 'danger'
        setNotificationType(notificationType)
        setNotificationMessage(data["message"])
        setLoading(false)
        setShowNotification(true)
      })
    }

    const renderNotes = () => {
        if (purchaseOrder.notes !== undefined ){
          return purchaseOrder.notes.map((note) => {
            return (
              <GridContainer key={note.id} className={classes.note}>
                <GridItem xs={12} md={8} >
                  <p>{note.description}</p>
                </GridItem>
                <GridItem xs={12} md={4} >
                  <p>Created By: {note.user}</p>
                  <p>Created At: {note.created_at}</p>
                </GridItem>
              </GridContainer>
            )
          })
        }
    }

    const renderLabelLinks = () => {
      if(purchaseOrder.shipping_label_urls !== undefined){
        let shipping_label_links = purchaseOrder.shipping_label_urls.map((url, index) => {
          return <a key={"shipping_" + index} href={url} target="_blank" >{(index ? ', ' : '')} Label {index+1}</a>
        })
        if (shipping_label_links.length) shipping_label_links = <p>Shipping Labels: {shipping_label_links}</p>;

        let ucc_128_label_links = purchaseOrder.ucc_128_label_urls.map((url, index) => {
          return <a key={"ucc128_" + index} href={url} target="_blank" >{(index ? ', ' : '')} Label {index+1}</a>
        })
        if (ucc_128_label_links.length) ucc_128_label_links = <p>UCC-128 Labels: {ucc_128_label_links}</p>;

        return [shipping_label_links, ucc_128_label_links]
      }
    }

    const expandDetails = () => {
      if (hidePoDetails) {
        return <span onClick={() => setHidePoDetails(false)} title="Click to expand"><ExpandMoreIcon /></span>
      } else {
        return <span onClick={() => setHidePoDetails(true)} title="Click to shrink"><ExpandLessIcon /></span>
      }
    }

    const renderItems = () => {
      if (purchaseOrder.items !== undefined ){
        return purchaseOrder.items.map((item) => {
          return (
            <div key={item.id} className={classes.poItemGridContainer}>
              <div className={classes.itemCode}><em>Item Code:</em>{item.item_code}</div>
              <div className={classes.mfgCode}><em>MFG Part No:</em>{item.mfg_part_no}</div>
              <div className={classes.status}><em>MFG Status:</em>{item.mfg_status}</div>
              <div className={classes.qty}><em>Qty:</em>{item.qty}</div>
              <div className={classes.shipmentInfo}><em>Shipment Info:</em>{item.shipment_info.map(info => <p>{info}</p>)}</div>
              <div className={classes.artThumbnail}>
                <em>Artwork:</em>
                {item.thumbnail_url !== "" ? <a href={item.front_image_url} target="_blank"><img src={item.thumbnail_url} height="120" /></a> : ''}
                {item.product_thumbnail_url !== "" ? <a href={item.product_thumbnail_url} target="_blank"><img src={item.product_thumbnail_url} height="120" /></a> : ''}
              </div>
              <div className={classes.qrCode}><em>Image QR Code:</em><img src={item.qr_code} height="120" width="auto" /></div>
              <div className={classes.productUpc}><em>Product UPC:</em><img src={item.product_upc} height="50" width="auto" /></div>
              <Button variant="text" onClick={() => reRipImage(item.id)}>Re-rip Image</Button>
            </div>
          )
        })
      }
    }

    useEffect(()=> {
        getPurchaseOrderData();
    },[])

    const notifyMissingPackingList = (e) => {
      e.preventDefault()
      setNotificationType('danger')
      setNotificationMessage("Packing list for selected PO is not currently available.")
      setShowNotification(true)
    }

  const classes = useStyles();
  return (
      <GridContainer>
        <Snackbar
          place="tr"
          color={notificationType}
          message={notificationMessage}
          open={showNotification}
          closeNotification={() => setShowNotification(false)}
          close
        />
        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.pageTitle}>Purchase Order Details</h3>
        </GridItem>
        <LoadingSpinner loading={loading} />

        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.poDetailsHeader}  color="rose" icon>
              { purchaseOrder.packing_list_available ? (
                <Button
                  component = {Link}
                  target="_blank"
                  to={`/purchase_orders/${purchaseOrder.id}/packing_list_${purchaseOrder.id}.pdf`}
                  color="primary"
                  className={"edit " + classes.openBtn}
                  size="sm"
                >
                  <Print/>
                </Button>
              ) : (
                <Button
                  component = {Link}
                  disabled
                  to="#"
                  color="primary"
                  className={"edit" + ` ${classes.openBtn} ${classes.disabledButton}`}
                  size="sm"
                >
                  <Print/>
                </Button>
              )}

              {expandDetails()}
              <CardIcon color="rose">
                <ContactMail />
              </CardIcon>
              <GridContainer>
                <GridItem xs={12} md={3} >
                  <h4 className={classes.cardIconTitle} style={{whiteSpace: "pre-line"}}>
                    {`PO NO: ${purchaseOrder.exported_order_id} \n CUSTOMER PO: ${purchaseOrder.customer_po}`}
                  </h4>
                </GridItem>
                <GridItem xs={12} md={3} >
                  <h4 className={classes.cardIconTitle}>SALES ORDER NO: {purchaseOrder.sales_order_no}</h4>
                </GridItem>
                <GridItem xs={12} md={3} >
                  <h4 className={classes.cardIconTitle}>CUSTOMER: {purchaseOrder.customer_name}</h4>
                </GridItem>
                <GridItem xs={12} md={3} >
                  <img src={purchaseOrder.poid_barcode_url} height="50" width="auto"/>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody className={classes.poDetailsBody} style={hidePoDetails ? {display: 'none'} : {}}>
              <hr />
              <GridContainer>
                <GridItem xs={12} md={3} >
                  <p>ORDER DATE: {purchaseOrder.order_date}</p>
                  <p>IMPORT DATE: {purchaseOrder.import_date}</p>
                  <p>EXPORT DATE: {purchaseOrder.export_date}</p>
                  <p>EXPECTED SHIP DATE: {purchaseOrder.ship_exp}</p>
                </GridItem>
                <GridItem xs={12} md={3} >
                  <p>STATUS: {purchaseOrder.status}</p>
                  { purchaseOrder.status_ats ? purchaseOrder.status_ats.map( item => <p key={item.toString()}>{item}</p>) : null}
                </GridItem>
                <GridItem xs={12} md={3} >
                  <p>SHIP TO: </p>
                  <p>{purchaseOrder.ship_to_name}</p>
                  <p>{purchaseOrder.ship_to_address_1}</p>
                  <p>{purchaseOrder.ship_to_address_2}</p>
                  <p>{purchaseOrder.ship_to_city}, {purchaseOrder.ship_to_state} {purchaseOrder.ship_to_zip}</p>
                </GridItem>
                <GridItem xs={12} md={3} >
                  <p>SHIP METHOD: {purchaseOrder.shipping_method}</p>
                  <p>SHIP ACCT: {purchaseOrder.shipping_acct}</p>
                  {renderLabelLinks()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ContactMail />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Purchase Order Items</h4>
            </CardHeader>
            <CardBody>
              {renderItems()}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ContactMail />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Notes</h4>
            </CardHeader>
            <CardBody>
              {renderNotes()}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
  );
}
