import request from 'services/Service.js';
import { BASE_BIN_LOCATIONS_API } from 'variables/api_urls';

export default class BinLocations {
  constructor() {
    this.resource_url = BASE_BIN_LOCATIONS_API;
  }

  search(query) {
    return request({
      method: 'get',
      url: `${this.resource_url}?page=${query["page"]}&per_page=${query["per_page"]}`
    })
  }

  delete(id) {
    return request({
      method: 'delete',
      url: `${this.resource_url}/${id}`
    })
  }

  getPickTicketPdf() {
    return request({
      method: 'get',
      url: `${this.resource_url}/pick_tickets.pdf`,
      responseType: 'blob'
    })
  }
}
