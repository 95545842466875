import request from 'services/Service.js';
import { BASE_AUTH_API, BASE_PASSWORD_API } from 'variables/api_urls';

export default class AuthService {
  constructor() {
    this.logged_in_endpoint = `${BASE_AUTH_API}/logged_in`;
    this.login_endpoint = `${BASE_AUTH_API}/login`;
    this.logout_endpoint = `${BASE_AUTH_API}/logout`;
    this.reset_request_endpoint = `${BASE_PASSWORD_API}/request_reset_link`;
    this.reset_password_endpoint = `${BASE_PASSWORD_API}/reset`;
  }

  retrieveStatus() {
    return request({
      method: 'get',
      url: this.logged_in_endpoint
    })
  }

  login(email, password) {
    return request({
      method: 'post',
      data: {email: email, password: password},
      url: this.login_endpoint
    })
  }

  logout() {
    return request({
      method: 'delete',
      url: this.logout_endpoint
    })
  }

  requestReset(email) {
    return request({
      method: 'post',
      data: {email: email},
      url: this.reset_request_endpoint
    })
  }

  reset(password, token) {
    return request({
      method: 'post',
      data: {token: token, password: password},
      url: this.reset_password_endpoint
    })
  }
}

