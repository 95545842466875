import axios from 'axios';

export default function (options) {
  const onSuccess = response => response.data,
    onError = (error) => {
      if (error.response) {
        const { response } = error;
        /* eslint-disable no-console */
        console.error('Status:', response.status);
        console.error('Data:', response.data);
        console.error('Headers:', response.headers);
      } else {
        /* eslint-disable no-console */
        console.error('Error Message:', error.message);
        /* eslint-enable no-console */
      }

      return Promise.reject(error.response || error.message);
    };

  options.headers = options.headers || {};
  options.headers.Accept = 'application/json';
  options.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
  options.headers['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").getAttribute("content")

  return axios(options)
    .then(onSuccess)
    .catch(onError);
}
