/*eslint-disable*/
import React, {useEffect, useState} from "react";

// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";


import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import LocalShipping from "@material-ui/icons/LocalShipping";
import CardBody from "../../components/Card/CardBody";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  regenButton: {
    "&:after": {
      width: "30px",
      height: "30px",
      lineHeight: "30px",
      fontSize: "30px",
      right: "0",
      content: '"\\2713"',
      color: "#43a047",
      position: "absolute",
      opacity: "0",
      transition: "0.5s",
    }
  },
  regenButtonDone: {
    "&:after": {
      right: "-30px",
      opacity: "1",
    }
  },
  btnFloat: {
    position: "relative",
    float: "right"
  },
  containerStyle: {
    padding: "20px"
  }
};

const useStyles = makeStyles(newStyles);

// Our table component
function FedexLabels({kickoffRegeneration, regenDone}) {


  const onSubmit = () => {
    kickoffRegeneration()
  }

  const regenButtonClasses = () => {
    return classes.regenButton + (regenDone ? " " + classes.regenButtonDone : "")
  }

  const classes = useStyles()
  return (
    <div style={{overflowX:"hidden"}} className="toggle-visibility">
      <GridContainer className={classes.containerStyle}>
        <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <LocalShipping />
          </CardIcon>
        </CardHeader>
        <CardBody>
        <GridContainer xs={12}>
        <GridItem xs={12} sm={6} md={7}>
          <p>
            After the Fedex driver has picked up for the day, please kick off new label generation for tomorrow by
            pressing this button (this will also automatically kick off at 9 pm).
          </p>
        </GridItem>
        <GridItem xs={12} sm={6} md={5}>
          <FormControl className={classes.btnFloat}>
            <Button color="primary" className={regenButtonClasses()} onClick={() => onSubmit()} >Redo Labels for Tomorrow</Button>
          </FormControl>
        </GridItem>
        </GridContainer>
        </CardBody>
          </Card>
      </GridContainer>
    </div>
  );
}


export default FedexLabels;
