import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
const endOfDayStyles = {
  ...styles,
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  clearButton: {
    cursor: "pointer",
  },
  endOfDayButtons: {
    minWidth: "60%",
    margin: "auto"
  },
  endOfDayContainer: {
    maxWidth: "800px",
    margin: "auto",
  },
  endOfDayCard: {
    "& > div > div:nth-child(1)": {
      paddingBottom: "15px",
      borderBottom: "2px solid #eee",
    }
  },
  pageTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
};

export default endOfDayStyles;