import React, {useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

// core components
import NavPills from "components/NavPills/NavPills.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// app components
import CutlistPanel from "../../app_components/Cutlist/CutlistPanel";
import LegCountPanel from "../../app_components/Cutlist/LegCountPanel";

customSelectStyle["selectMenuItem"]["&:hover"] = {
  backgroundColor: "rgba(0,0,0,0.1)",
  boxShadow: "none",
  color: "inherit",
}
customSelectStyle["selectMenuItemSelected"]["color"] = "white!important" // To overwrite the hover effect color

const styles = {
  ...customSelectStyle,
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  pageTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
};

const useStyles = makeStyles(styles);
export default function () {
  const [needLegCounts, setNeedLegCounts] = React.useState(false);
  const classes = useStyles();

  const handleChange = (activePanelIndex)=> {
      setNeedLegCounts(true);
  }

  return (
      <>
          <GridContainer>
              <GridItem xs={12}>
                  <h3 className={classes.pageTitle}>Cutlist</h3>
                  <NavPills
                      color="warning"
                      alignCenter
                      onChange={handleChange}
                      tabs={[
                          {
                              tabButton: "Cut List",
                              tabContent: (
                                  <CutlistPanel classes={classes} />
                              ),
                          },
                          {
                              tabButton: "Leg Counts",
                              tabContent: (
                                  <LegCountPanel needData={needLegCounts} classes={classes} />
                              ),
                          },
                      ]}
                  />
              </GridItem>
          </GridContainer>
      </>

  );
}
