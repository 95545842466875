import React, {useEffect, useRef, useState} from "react";
import Datetime from "react-datetime";
import { CSVLink, CSVDownload } from "react-csv";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";

//core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";

// app services
import CutlistService from "services/CutlistService.js";
import CustomerService from "services/CustomerService";

// app components
import CutListCategoryTable from "./CategoryTable";
import LoadingSpinner from "app_components/LoadingSpinner";
import MultiSelect from "app_components/MultiSelect"
import moment from "moment";

export default function CutlistPanel({tableData, classes}) {
    const [cutlistData, setCutlistData] = useState([]);

    // Filtering constants and functions
    const [poBuyOnly, setPoBuyOnly] = useState(('all'));
    const [categoryOptions,setCategoryOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState( []);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [queryParms, setQueryParms] = React.useState({});
    const [dateKey, setDateKey] = useState(1);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [customers, setCustomers] = React.useState([]);
    const [skuOptions,setSkuOptions] = useState([]);
    const [skus,setSkus] = React.useState([]);
    const [csvData, setCsvData] = useState('');
    const [loading, setLoading] = useState(true);
    const [startDateKey, setStartDateKey] = useState(100);
    const [endDateKey, setEndDateKey] = useState(200);

    const csvLink = useRef();

    let startdateRef = React.createRef();
    let enddateRef = React.createRef();

    const registerParmChange = (key, value) => {
        const updatedParms = queryParms;
        // Checks if string or array is empty and removes from the query if it is
        if (value.length > 0) {
            updatedParms[key] = value;
        } else {
            delete updatedParms[key]
        }
        setQueryParms(updatedParms);
        console.log(updatedParms);
    }

    useEffect(()=>{
        if(startDateKey>100)
            startdateRef.current.focus();
    },[startDateKey] )

    useEffect(()=>{
        if(endDateKey>200)
            enddateRef.current.focus();
    },[endDateKey] )

    useEffect(()=>{
        console.log('updated');
        getCustomerSelectOptions();
    }, [poBuyOnly])

    const getCutListData = (params) => {
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        setLoading(true);
        new CutlistService().retrieve(queryString)
            .then((data) => {
                if(data.status == 'error'){
                    alert(data.message);
                    setCutlistData([])
                    setLoading(false);
                    return;
                }
                console.log(data);
                data.cutlist.data.forEach(category => {
                    if(category.name == 'Grand Totals'){
                        category.rows[0].totals.push(data.shipped_today)
                    }
                })
                setCutlistData(data.cutlist.data);
                setCategoryOptions(data.categories);
                setSkuOptions(data.skus);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getCustomerSelectOptions = async () => {
        let queryParm = ''
        if (poBuyOnly == 'true') { queryParm = '?po_buy_only=true'}
        new CustomerService().select_options(queryParm)
          .then((data) => {
              data['select_options'].unshift(['Deselect All', 'deselect'])
              data['select_options'].unshift(['Select All', 'select'])
              setCustomerOptions(data['select_options']);
          })
          .catch((error) => {
              console.log(error);
          });
    }

    const getCutListCSV = async (params) => {
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        new CutlistService().retrieveCSV(queryString)
            .then((data) => {
                setCsvData(data.csv);
                // Ugly but necessary - react-csv's async functionality is famously broken
                // This is the standard work-around
                csvLink.current.link.click();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getCutListData({});
        getCustomerSelectOptions();
    },[]);


    const clearStartInput = (e) => {

        setStartDate('');
        setStartDateKey(startDateKey+1);
        registerParmChange("cutlist_start_date", '')

    }

    const clearEndInput = (e) => {

        setEndDate('');
        setEndDateKey(endDateKey+1);
        registerParmChange("cutlist_end_date", '')

    }
 
    const startDateKeyCheck = (e) => {
        if(e.key == 'Enter'){

            let inputParse = e.target.value.split('-').join('/')
            let formattedDate = moment(inputParse).format("MM/DD/YYYY")

            e.target.value = formattedDate
            setStartDate(formattedDate);
            registerParmChange("cutlist_start_date", formattedDate)
        }
    }

    const endDateKeyCheck = (e) => {
        if(e.key == 'Enter'){

            let inputParse = e.target.value.split('-').join('/')
            let formattedDate = moment(inputParse).format("MM/DD/YYYY")

            e.target.value = formattedDate
            setEndDate(formattedDate);
            registerParmChange("cutlist_end_date", formattedDate)
        }
    }

    const startInputFocusChange = (e) => {

        let inputParse = e.target.value.split("-").join("/");
        let formattedDate = moment(inputParse).format("MM/DD/YYYY");
        if(moment(formattedDate, "MM/DD/YYYY", true).isValid()){
            e.target.value = formattedDate;
            setStartDate(formattedDate);
            registerParmChange("cutlist_start_date", formattedDate);
        }
    }

    const endInputFocusChange = (e) => {

        let inputParse = e.target.value.split("-").join("/");
        let formattedDate = moment(inputParse).format("MM/DD/YYYY");
        if(moment(formattedDate, "MM/DD/YYYY", true).isValid()){
            e.target.value = formattedDate;
            setEndDate(formattedDate);
            registerParmChange("cutlist_end_date", formattedDate);
        }
    }

    // handlers
    const handleStartChange = (e) => {

        if(e._isAMomentObject == true){
            setStartDate(e);
            registerParmChange("cutlist_start_date", e.format("MM/DD/YYYY"));
        }

    }

    const handleEndChange = (e) => {

        if(e._isAMomentObject == true){
            setEndDate(e);
            registerParmChange("cutlist_end_date", e.format("MM/DD/YYYY"));
        }

    }

    const handleCustomerChange = (newCustomerList) => {
        newCustomerList.forEach( i => {
            if(i == 'select'){
                let customerArr = []
                for(let j of customerOptions){
                    if(j[0] != 'Select All' && j[0] != 'Deselect All'){
                        customerArr.push(j[1])
                    }
                }
                setCustomers(customerArr);
                registerParmChange('customer_numbers', customerArr);
            }
            else if(i == 'deselect'){
                setCustomers([]);
                registerParmChange('customer_numbers', []);
            }else{
                setCustomers(newCustomerList);
                registerParmChange('customer_numbers', newCustomerList);
            }
        })
    }

    const handleCategoryChange = (newCategoryList) => {
        setSelectedCategories(newCategoryList);
        registerParmChange('categories', newCategoryList)
    }

    const handleSkuChange = (newSkuList) => {
        setSkus(newSkuList);
        registerParmChange('skus', newSkuList);
    }

    const handleSearchSubmit = (e)=>{
        e.preventDefault()
        getCutListData(queryParms);
    } ;

    const exportHandler = (e) => {
        getCutListCSV(queryParms);
    }

    const handleResetFilter = () => {
        setSelectedCategories([]);
        setSkus([]);
        setCustomers([]);
        setStartDate('');
        setEndDate('');
        setDateKey(dateKey + 1);
        setQueryParms({});
        getCutListData({});
    }

    const toggleCustomerType = (e) => {
        if(e.target.checked){
            setPoBuyOnly('true')
            registerParmChange('po_buy_only', 'true')
        }else{
            setPoBuyOnly('false')
            registerParmChange('po_buy_only', 'false')
        }
    }

    return (
        <GridContainer>

            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <div style={{position: 'relative', float: 'right', top: '10px'}}>
                            <span>All Customers</span>
                            <Switch color='default' onChange={toggleCustomerType} />
                            <span>PO Buys</span>
                        </div>
                        <form onSubmit={handleSearchSubmit}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={11}>
                                    <Card>
                                        <CardHeader color="primary" icon>
                                            <h4 className={classes.cardIconTitle}>Search</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                            <div  style={{height: '60px', paddingTop: '25px'}}>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <FormControl fullWidth key={startDateKey} onDoubleClick={clearStartInput} onKeyDown={startDateKeyCheck} onBlur={startInputFocusChange} >
                                                            <Datetime
                                                                key={dateKey}
                                                                value={startDate}
                                                                timeFormat={false}
                                                                inputProps={{ placeholder: "Start Date", ref: startdateRef }}
                                                                input={true}
                                                                onChange={handleStartChange}
                                                                closeOnSelect={true}
                                                            />
                                                        </FormControl>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <FormControl fullWidth key={endDateKey} onDoubleClick={clearEndInput} onKeyDown={endDateKeyCheck} onBlur={endInputFocusChange} >
                                                            <Datetime
                                                                key={dateKey}
                                                                value={endDate}
                                                                timeFormat={false}
                                                                inputProps={{ placeholder: "End Date", ref: enddateRef }}
                                                                input={true}
                                                                onChange={handleEndChange}
                                                                closeOnSelect={true}
                                                            />
                                                        </FormControl>
                                                    </GridItem>
                                                </GridContainer>
                                            </div>
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={6}>
                                            <div  style={{height: '60px'}}>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <FormControl fullWidth className={classes.selectFormControl}>
                                                            <MultiSelect
                                                              title="Customers"
                                                              values={customers}
                                                              options={customerOptions}
                                                              onChange={handleCustomerChange}
                                                              onSubmit={handleSearchSubmit}
                                                              autoFocus={false}
                                                            />
                                                        </FormControl>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <FormControl fullWidth className={classes.selectFormControl}>
                                                            <MultiSelect
                                                                title="CATEGORY"
                                                                values={selectedCategories}
                                                                options={categoryOptions}
                                                                onChange={handleCategoryChange}
                                                                onSubmit={handleSearchSubmit}
                                                            />
                                                        </FormControl>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <FormControl fullWidth className={classes.selectFormControl}>
                                                            <MultiSelect
                                                                title="SKU's"
                                                                values={skus}
                                                                options={skuOptions}
                                                                onChange={handleSkuChange}
                                                                onSubmit={handleSearchSubmit}
                                                            />
                                                        </FormControl>
                                                    </GridItem>
                                                </GridContainer>
                                            </div>
                                            </GridItem>
                                                </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}>
                                    <div style={{paddingTop: '30%'}}>
                                        <Button type="submit"
                                                color="primary"
                                                size="sm"
                                                style={{width: '100%'}}
                                        >Search</Button>
                                        <Button color="primary"
                                                size="sm"
                                                style={{width: '100%'}}
                                                onClick={exportHandler}>Export CSV
                                        </Button>
                                        <Button size="sm"
                                                style={{width: '100%'}}
                                            onClick={handleResetFilter}
                                        >Reset</Button>
                                        <CSVLink filename={"cutlist.csv"} ref={csvLink} className="hidden" data={csvData}></CSVLink>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </form>

                        <GridContainer>
                            <GridItem xs={12}>
                                {loading ?
                                    <Card key="0">
                                        <CardBody>
                                            <br/><br/>
                                            <LoadingSpinner loading={true}/>
                                            <br/><br/>
                                        </CardBody>
                                    </Card>
                                    :
                                    cutlistData.map((category, i) => {
                                            const headers = (category.name !== "Grand Totals") ?
                                                ["Sub-Category", "Product", "SKU" ,"Total","Late", "Today", "Tomorrow", "Later"] :
                                                ["", "", "" ,"Total","Late", "Today", "Tomorrow", "Later", "Shipped Today"];
                                            // if(category.name == 'Grand Totals'){
                                            //     category.rows[0].totals.push(shippedToday);
                                            // }
                                        return   <Card key={i}>
                                                <CardHeader color="primary" icon>
                                                    <h3 className={classes.cardIconTitle}>{category.name}</h3>
                                                </CardHeader>
                                                <CardBody>
                                                    <CutListCategoryTable tableData={category} tableHead={headers} />
                                                </CardBody>
                                            </Card>
                                        })
                                }
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}