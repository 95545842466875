import React, {useEffect, useRef, useState} from "react";
import {CSVLink} from "react-csv";

// @material-ui/core components
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";

// app services
import CutlistService from "services/CutlistService";
import LoadingSpinner from "../LoadingSpinner";
export default function LegCountPanel({needData, classes}) {
    const [legCountData, setLegCountData] = useState([]);
    const [loading, setLoading] = useState(false);
    const getLegCountData = async (params) => {
        setLoading(true);
        new CutlistService().legCounts()
            .then((data) => {
                setLegCountData(data["leg_counts"]);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        if(needData) {
            getLegCountData();
        }
    },[needData]);

    const csvLink = useRef();
    const [csvData, setCsvData] = useState('');
    const getLegCountCSV = async (params) => {
        new CutlistService().legCountsCSV()
            .then((data) => {
                setCsvData(data.csv);
                // Ugly but necessary - react-csv's async functionality is famously broken
                // This is the standard work-around
                csvLink.current.link.click();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const exportHandler = (e) => {
        getLegCountCSV();
    }
    if(!needData) {
        return (
            <></>
        )
    }
    return (
        <GridContainer>
            <GridItem xs={3} />
            <GridItem xs={6}>

                    <Card>
                        <CardHeader color="warning" icon>
                            <GridContainer>
                                <GridItem xs={8}>
                                    <CardIcon color="warning">
                                        <Assignment />
                                    </CardIcon>
                                    {/*<h4 className={classes.cardIconTitle}>Leg Counts</h4>*/}
                                </GridItem>
                                <GridItem xs={4}>
                                    <Button color="primary"
                                            size="sm"
                                            style={{marginTop: '20px'}}
                                            onClick={exportHandler}
                                    >Export CSV
                                    </Button>
                                    <CSVLink filename={"leg_count.csv"} ref={csvLink} className="hidden" data={csvData}></CSVLink>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody>
                            {loading ?
                                <>
                                    <br/><br/>
                                    <LoadingSpinner loading={true}/>
                                    <br/><br/>
                                </>
                                :
                                <Table
                                    tableHeaderColor="primary"
                                    tableHead={["Leg Type", "Count"]}
                                    tableData={legCountData}
                                />
                            }
                        </CardBody>
                    </Card>
            </GridItem>
        </GridContainer>
    )
}