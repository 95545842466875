import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

function SkuSearchButton({ skuFilterList, handleSkuFilterSet, sku }) {
    const classes = useStyles();

    const handleToggle = async () => {
        handleSkuFilterSet(sku)
    }

    const isSkuSelected = () => {
        return skuFilterList.includes(sku)
    }

    return (
        <Checkbox
          checked={isSkuSelected()}
          tabIndex={-1}
          onClick={handleToggle}
          checkedIcon={
              <Check className={classes.checkedIcon} />
          }
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
              checked: classes.checked,
              root: classes.checkRoot,
          }}
        />
    )
}

export default SkuSearchButton