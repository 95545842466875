import React, {useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {makeStyles} from "@material-ui/core/styles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";

const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center",
    },
    cardCategory: {
        margin: "0",
        color: "#999999",
    },
    wizardHeader: {
        textAlign: "center",
        padding: "25px 0 35px",
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    alignRight: {
        fontWeight: "300",
        textAlign: "left"
    },
    center: {
        textAlign: "center",
    },
    inputCol: {
        position: "relative",
        float: "left",
        width: "85%"
    }
};
//todo organize imports
const useStyles = makeStyles(styles);
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";

import PrintStationService from "services/PrintStationService";
import PrinterService from "services/PrinterService";

export default function PrintUpcPanel(props) {
    const {activeTab} = props;

    const classes = useStyles();
    const [poiid, setPoiid] = React.useState("");

    useEffect(()=>{
        console.log("PRINT UPC PANEL INITIATED")
        if(activeTab === 1)
            handlePanelEntry();

    }, [activeTab])

    const handlePanelEntry = ()=> {
        alert("You've stepped into PrintUpc");
    }
    const getUPC = async () => {
        console.log('OFFICIAL: getUPC');

        new PrintStationService().getUPC(poiid)
            .then((data) => {
                console.log('back from get UPC');
                console.log(data);

                printUPC(data.upc);
                setPoiid('');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const printUPC = (data) => {
        console.log('PRINTING: ' + data)

        new PrinterService().printUPC(data)
    }

    const handleSubmit = (e)=> {
        e.preventDefault();
        getUPC();
    }

    return (
        <Card>
            <CardHeader className={classes.infoText}>
                <h3 >
                    Print UPC Labels
                </h3>
            </CardHeader>
            <CardBody>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={10}>
                        <h4 className={classes.alignRight}>
                            Please scan the BARCODE on the purchase order item:
                        </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={10} >
                        <form onSubmit={handleSubmit} >
                            <div className={classes.inputCol}>
                                <CustomInput
                                    labelText={
                                        <span>
                                      Purchase Order Item Barcode
                                    </span>
                                    }
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: poiid,
                                        onChange: (event) => {
                                            setPoiid(event.target.value);
                                        },
                                    }}
                                />
                            </div>
                            <div className={classes.center}>
                                <Button type="submit"
                                        color="primary"
                                >Submit</Button>
                            </div>
                            <br />
                            <br />
                            <Muted>
                                <p>
                                    Scan the purchase order item in order to print the UPC.
                                </p>
                                <p>
                                    <b>Note:</b> You must have the DYMO LabelWriter 450 attached to the work station via USB. You must have also installed the DYMO drivers.
                                </p>
                                <p>
                                    <b>Download Drivers: </b>
                                    <Link
                                        to="http://www.dymo.com/en-US/online-support/dymo-user-guides"
                                        target="_blank"
                                    >Downloads: Software, Drivers & User Guides</Link>
                                </p>
                            </Muted>
                        </form>

                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>

    )
}