import qz from "qz-tray";

const launchQZ = () => {
    console.log('Launching QZ tray')
    if (!qz.websocket.isActive()) {
        //Retry 5 times, pausing 1 second between each attempt
        startConnection({retries: 5, delay: 1});
    }
}

const printFileToQZ = (label) => {
    var printerName = 'ZPL Label Printer';
    qz.printers.find(printerName).then(function(printer) {
        var config = qz.configs.create(printer);
        var data = [
            { type: 'raw', format: 'command', flavor: 'file', data: label }
        ];
        return qz.print(config, data);
    }).catch(logError);
}

const printToQZ = (label) => {
    var printerName = 'ZPL Label Printer';
    qz.printers.find(printerName).then(function(printer) {
        var config = qz.configs.create(printer);
        var data = [label];
        return qz.print(config, data);
    }).catch(logError);
}

const startConnection = (config) => {
    console.log('Starting connection:');
    console.log(config);
    if (!qz.websocket.isActive()) {
        qz.websocket.connect(config).then(function() {
            console.log('Activated QZ tray connection');
        }).catch(logError);
    } else {
        console.log('An active connection with QZ already exists.');
    }
}

const endConnection = () => {
    if (qz.websocket.isActive()) {
        qz.websocket.disconnect().then(function() {
            console.log('Deactivated QZ tray connection');
        }).catch(logError);
    } else {
        console.log('No active connection with QZ exists.');
    }
}

const findDefaultPrinter = () => {
    qz.printers.getDefault().then(function(data) {
        console.log('Found default printer ' + data);
        return data;
    }).catch(logError);
}

const findPrinter = (printerName) => {
    qz.printers.find(printerName).then(function(data) {
        console.log(printerName + ' found: ' + data);
        return data;
    }).catch(logError);
}

const findPrinters = () => {
    qz.printers.find().then(function(data) {
        for(var i = 0; i < data.length; i++) {
            console.log('Printer: ' + i + ': ' + data[i]);
        }
        return data;
    }).catch(logError);
}

const logError = (err) => {
    console.log('Logging error:')
    console.error(err);
}

export { launchQZ, printFileToQZ, printToQZ }