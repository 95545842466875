import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Home from "../components/Home";
import Orders from "../app_components/Orders";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";


export default (
    <Router>
        <Switch>
            <Route path="/orders" exact component={Orders} />
            <Route path="/auth" component={AuthLayout} />
            <Route path="/admin" component={AdminLayout} />
            {/*<Route path='/exportcsv' component={() => {*/}
            {/*    window.location.href = 'http://localhost:3001/homepage/non_react_route.csv';*/}
            {/*    return null;*/}
            {/*}}/>            */}
            <Redirect from="/" to="/admin/dashboard" />
        </Switch>
    </Router>
);