import React, {useEffect, useState} from "react";

// @material-ui/core components
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// app services
import CustomerService from "services/CustomerService";

export default function SelectCustomers({values, onChange}) {
  const [selectOptions, setSelectOptions] = useState([])

  const getCustomerSelectOptions = async (params) => {
    new CustomerService().select_options()
      .then((data) => {
        setSelectOptions(data['select_options']);
        console.log(data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getCustomerSelectOptions();
  }, [])

  const currentValues = () => {
      if(selectOptions.length ===0) {
          return([])
      }

      return(values.map((value, key) => {
          return(selectOptions.find((v) => v[1] === value))
      }))
  }

  if(selectOptions===[]){
      return(
          <></>
      )
  }

  return (
      <>
        <Autocomplete
          value={currentValues()}
          onChange={onChange}
          multiple
          disablePortal
          options={selectOptions}
          renderInput={(params) => <TextField {...params} variant="standard" label="Customers" />}
          getOptionLabel={(option) => {
              return(option[0])
          }}
      />
      </>

  )
}