import React, {useEffect, useState} from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import {Link} from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import "assets/css/chartist.css";
import PieChart from "app_components/Dashboard/PieChart.js";
import LineGraph from "app_components/Dashboard/LineGraph.js";
import SkuSearchButton from "../../app_components/Dashboard/skuSearchBtn";
import DashboardService from "services/DashboardService.js";
import SimpleSearchModal from "../../app_components/Dashboard/simpleSearchModal";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import PurchaseOrderService from "../../services/PurchaseOrderService";
import FormControl from "@material-ui/core/FormControl";
import SimpleCheckboxForTable from "../../app_components/Dashboard/simpleCheckboxForTable";
import OpenInNew from "@material-ui/icons/OpenInNew";

import DatePicker from "react-multi-date-picker";

const dashboardStyles = {
    ...styles,
    chartFilterOptions: {
      '& > *': {
        marginBottom: "15px"
      },
      '& button': {
        margin: "0!important"
      },
      '& .rmdp-today span': {
        backgroundColor: "inherit",
        color: "inherit",
      }
    },
    pieChartCard: {
      height: "calc(100% - 60px)"
    },
    productionSummaryBtn: {
      padding: "5px!important",
      top: "5px",
      right: "5px",
      position: "absolute",
      '& svg': {
        margin: "0"
      }
    },
    submitFilterButtonRow: {
      '& > div:first-child': {
        paddingRight: "5px!important"
      },
      '& > div:last-child': {
        paddingLeft: "5px!important"
      }
    },
    datePicker: {
      '& .rmdp-range':{
        background: "#33CCCC"
      },
      '& .rmdp-week-day': {
        color: "#33CCCC"
      },
      '& .rmdp-arrow': {
        borderColor: "#33CCCC",
      },
      '& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover':{
        backgroundColor: "rgba(51, 204, 204, 0.4)!important"
      },
      '& .rmdp-day.rmdp-selected span:not(.highlight)': {
        backgroundColor: "#33CCCC"
      },
      '& input': {
        color: "#555",
        border: "none",
        borderBottom: "1px solid #ced4da",
        width: "100%",
        borderRadius: "0",
        height: "36px",
        padding: "7px 0",
        fontSize: "12px",
        lineHeight: "1.428571429",
        '&:focus': {
          border: "none",
          boxShadow: "none",
          borderBottom: "2px solid #33CCCC"
        }
      }
    },
    dateRange: {
      marginBottom: "0",
      "@media (max-width: 1919px)": {
        marginBottom: "15px",
      }
    },
    yearsBtn: {
      width: '100%',
    }
}

const useStyles = makeStyles(dashboardStyles);


export default function Dashboard() {
  const yesterday = new Date
  yesterday.setDate(yesterday.getDate()-1)

  // Default values for date range
  const defaultStart = new Date()
  defaultStart.setMonth(defaultStart.getMonth()-1)
  const defaultEnd = new Date()

  const defaultParams = {
    years: new Date().getFullYear(),
    date_range_start: `${defaultStart.getMonth()+1}/${defaultStart.getDate()}`,
    date_range_end: `${defaultEnd.getMonth()+1}/${defaultEnd.getDate()}`,
  }

  const classes = useStyles();

  const [avgProdTime, setAvgProdTime] = useState()
  const [shippedYesterday, setShippedYesterday] = useState()
  const [shippedToday, setShippedToday] = useState()
  const [lateOrders, setLateOrders] = useState()
  const [dueToday, setDueToday] = useState()
  const [dueTomorrow, setDueTomorrow] = useState()
  const [queryParms, setQueryParms] = useState(defaultParams);

  // Production Volume Report
  const [productionVolumeData, setProductionVolumeData] = useState([])
  const [productionVolumeMode, setProductionVolumeMode] = useState("days")
  const [skuOptions, setSkuOptions] = useState([]);
  const [skuFilterModal, setSkuFilterModal] = useState(false)
  const [skuFilterList, setSkuFilterList] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [categoryFilterModal, setCategoryFilterModal] = useState(false)
  const [categoryFilterList, setCategoryFilterList] = useState( [])
  const [lineChartLoading, setLineChartLoading] = useState(false)
  const [pieChartLoading, setPieChartLoading] = useState(false)
  const [dateRange, setDateRange] = useState([defaultStart, defaultEnd]);
  const [filterYears, setFilterYears] = useState([new Date()])
  const [filterSummary, setFilterSummary] = useState("")

  let dashboardService = new DashboardService()
  let purchaseOrderService = new PurchaseOrderService()
  const getDashboardData = async () => {
    setPieChartLoading(true)
    dashboardService.retrieve("production_summary&shipping_metrics").then((data) => {
        setAvgProdTime(data.shipping_metrics.average_production_time)
        setShippedYesterday(data.shipping_metrics.shipped_yesterday)
        setShippedToday(data.shipping_metrics.shipped_today)
        setLateOrders(data.production_summary.late_items)
        setDueToday(data.production_summary.due_today)
        setDueTomorrow(data.production_summary.due_tomorrow)
      setPieChartLoading(false)
    })
  }

  const searchProductionVolume = async (params) => {
    setLineChartLoading(true)
    setProductionVolumeData([]) // Forces chart key to re-fresh
    let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    dashboardService.retrieve("production_volume&" + queryString).then((data) => {
      setFilterSummary(parseFilterSummary())
      setProductionVolumeData(data.production_volume_data.data)
      setProductionVolumeMode(data.production_volume_data.mode)
      setLineChartLoading(false)
    })
  }

  const parseFilterSummary = () => {
    return `
    Viewing records from ${queryParms.date_range_start} to ${queryParms.date_range_end} for the years: ${queryParms.years.toString().replace(/,/g, ', ')}.
    `
  }

  useEffect(() => {
    getDashboardData();
    getSkuOptions();
    getCategoryOptions();
    searchProductionVolume(queryParms)
  }, [])

  const getSkuOptions = () => {
    new PurchaseOrderService().retrieveSkuOptions('show_all_skus=true')
        .then((data) => {
          console.log("Setting data");
          console.log(data);
          setSkuOptions(data['sku_options']);
        })
        .catch((error) => {
          console.log(error);
        });
  }

  const getDateRangeMax = () => {
    let defaultMaxDate = new Date(new Date().getFullYear(), 11, 31)
    let currentStart = new Date(dateRange[0])
    let fullYearSelectMaxDate = new Date(currentStart.getFullYear()+1, currentStart.getMonth(), currentStart.getDate()-1)
    return new Date(Math.min.apply(null, [defaultMaxDate, fullYearSelectMaxDate]))
  }

  const getCategoryOptions = () => {
    purchaseOrderService.retrieveCategoryOptions()
      .then((data) => {
        console.log("Setting data");
        console.log(data);
        setCategoryOptions(data['cutlist_category_options']);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const clearSkuOptions = () => {
    setSkuFilterList([])
    registerParmChange('skus', '')
    searchProductionVolume(queryParms)
  }

  const clearCategoryOptions = () => {
    setCategoryFilterList([])
    registerParmChange('categories', '')
    searchProductionVolume(queryParms)
  }

  const formattedSkuOptions = () => {
      return skuOptions.map(skuOpt => {
        return [skuOpt, <SkuSearchButton skuFilterList={skuFilterList} handleSkuFilterSet={handleSkuFilterSet} sku={skuOpt}/>]
      })
  }

  const formattedCategoryOptions = () => {
    return categoryOptions.map(catOpt => {
      return [catOpt, <SimpleCheckboxForTable list={categoryFilterList} handleToggle={handleCategoryFilterSet} listItem={catOpt}/> ]
    })
  }

  const handleSkuFilterSet = (sku) => {
    let result = []
    if(skuFilterList.includes(sku)) {
      result = skuFilterList.filter( item => item !== sku)
    }else{
      result = [...skuFilterList, sku]
    }
    setSkuFilterList(result)
    registerParmChange('skus', result.join(","))
  }

  const handleCategoryFilterSet = (category) => {
    console.log('category',category)
    let result = []
    if(categoryFilterList.includes(category)) {
      result = categoryFilterList.filter( item => item !== category)
    }else{
      result = [...categoryFilterList, category]
    }
    setCategoryFilterList(result)
    registerParmChange('categories', result.join(","))
  }

  const handleDateRangeSet = (dateRange) => {
    console.log(new Date(dateRange[0]).getFullYear())
    console.log(new Date(dateRange[1] || dateRange[0]))
    setDateRange(dateRange)
    registerParmChange('date_range_start', `${dateRange[0]}`)
    registerParmChange('date_range_end', `${dateRange[1] || dateRange[0]}`)
  }

  const handleFilterYearsSet = (years) => {
    setFilterYears(years.sort())
    registerParmChange('years', years.join(","))
  }

  const registerParmChange = (key, value) => {
    const updatedParms = queryParms;
    updatedParms[key] = value;
    setQueryParms(updatedParms);
    console.log(updatedParms)
  }

  const resetProductionVolumeFilter = async () => {
    setFilterYears([new Date()])
    setDateRange([defaultStart, defaultEnd])
    setSkuFilterList([])
    setCategoryFilterList([])
    setQueryParms(defaultParams)
    searchProductionVolume(defaultParams)
  }

  return (
    <div>
      <SimpleSearchModal
        data={formattedSkuOptions()}
        tableHeader={["SKU", "filter"]}
        modalDisplay={skuFilterModal}
        closeModal={() => setSkuFilterModal(false)}
        clearOptions={() => clearSkuOptions()}
        applySearch={() => searchProductionVolume(queryParms)}
      />
      <SimpleSearchModal
        data={formattedCategoryOptions()}
        tableHeader={["Category", "filter"]}
        modalDisplay={categoryFilterModal}
        closeModal={() => setCategoryFilterModal(false)}
        clearOptions={() => clearCategoryOptions()}
        applySearch={() => searchProductionVolume(queryParms)}
      />
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader stats icon>
              <CardIcon color="warning" >
                <Icon>info_outline</Icon>
              </CardIcon>
              <h4 className={classes.cardCategory}>Average Production Time</h4>
              <h3 className={classes.cardTitle}>{avgProdTime} days
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                10/26/2021 - {new Date().toLocaleDateString("en-US")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader stats icon>
              <CardIcon color="warning">
                <Icon>info_outline</Icon>
              </CardIcon>
              <h4 className={classes.cardCategory}>Shipped Yesterday</h4>
              <h3 className={classes.cardTitle}>{shippedYesterday}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {yesterday.toLocaleDateString("en-US")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader stats icon>
              <CardIcon color="warning">
                <Icon>info_outline</Icon>
              </CardIcon>
              <h4 className={classes.cardCategory}>Shipped Today</h4>
              <h3 className={classes.cardTitle}>{shippedToday}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {new Date().toLocaleDateString("en-US")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.pieChartCard}>
            <CardHeader>
              <CardIcon color="warning">
                <Icon>moving_outlined</Icon>
              </CardIcon>
              <h4 className={classes.cardTitle}>Production Summary</h4>
              <Button className={classes.productionSummaryBtn} color="primary" href="/production_summary" target="_blank">
                <OpenInNew />
              </Button>
            </CardHeader>
            <CardBody>
              <PieChart late={lateOrders} today={dueToday} tomorrow={dueTomorrow} loading={pieChartLoading} />
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                {lateOrders} Late | {dueToday} Due Today | {dueTomorrow} Due Tomorrow
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card chart className={classes.cardHover}>
            <CardHeader>
              <CardIcon color="warning">
                <Icon>moving_outlined</Icon>
              </CardIcon>
              <h4 className={classes.cardTitle}>Production Volume</h4>


            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={8} md={8} lg={9}>
                  <LineGraph data={productionVolumeData} displayMode={productionVolumeMode} dateRange={dateRange} loading={lineChartLoading} />
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={3} className={classes.chartFilterOptions}>
                  <GridContainer>
                    <GridItem xs={12} xl={6}>
                      <FormControl fullWidth className={classes.datePicker + " " + classes.dateRange}>
                        <DatePicker
                          range
                          disableYearPicker
                          hideYear
                          value={dateRange}
                          format="MM/DD"
                          minDate={new Date(new Date().getFullYear()-1, 0, 1)}
                          maxDate={getDateRangeMax()}
                          placeholder="Select date range"
                          onChange={handleDateRangeSet}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} xl={6}>
                      <FormControl fullWidth className={classes.datePicker}>
                        <DatePicker
                          onlyYearPicker
                          format="YYYY"
                          minDate={new Date(2015, 0, 1)}
                          maxDate={new Date(new Date().getFullYear(), 11, 31)}
                          value={filterYears}
                          placeholder="Select Years"
                          render={(value, openCalendar) => {
                            return (
                            <Button className={classes.yearsBtn} onClick={openCalendar} title={filterYears.map(year => new Date(year).getFullYear()).join(", ")}>
                              Years
                            </Button>
                            )
                          }}
                          onChange={handleFilterYearsSet}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <FormControl fullWidth>
                    <Button color="gray" variant="text" onClick={() => setSkuFilterModal(true)}>Filter By SKU</Button>
                  </FormControl>
                  <FormControl fullWidth>
                    <Button color="gray" variant="text" onClick={() => setCategoryFilterModal(true)}>Filter By Category</Button>
                  </FormControl>
                  <GridContainer className={classes.submitFilterButtonRow}>
                    <GridItem xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Button variant="text" color="primary" onClick={() => searchProductionVolume(queryParms)}>Apply</Button>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Button variant="text" color="danger" onClick={() => resetProductionVolumeFilter()}>Reset</Button>
                      </FormControl>
                    </GridItem>
                  </GridContainer>

                </GridItem>
              </GridContainer>

            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                {filterSummary}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
