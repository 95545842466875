import React, {useEffect} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";
import Table from "components/Table/Table.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import {infoColor} from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import ShipmentPane from  "app_components/PrintStation/ShipmentPane.js"

// app services
import PrintStationService from "services/PrintStationService";
import PrinterService from "../../services/PrinterService";
import {Link} from "react-router-dom";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
    warningText: {
        color: infoColor,
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
  center: {
     textAlign: "center",
  },
  doneBtn: {
    position: "relative",
    float: "right",
    marginRight: "50px"
  },
  cancelLinkCont: {
    position: "relative",
    float: "right",
    padding: "20px",
    '& a:link, & a:visited, & a:hover, & a:active': {
      color: "#3cc",
    },
    '& a:hover': {
      textDecoration: "underline",
    }
  },
  tableStyle: {
    "& div table": {
      "& tr": {border: "hidden !important"}
      }
  },
  colCheckBox: {
    padding: "0"
  },
  ...customSelectStyle,
  ...tableStyles
};

const useStyles = makeStyles(styles);
const Step3 = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const [manualStatus, setManualStatus] = React.useState({});
    const [purchaseOrder, setPurchaseOrder] = React.useState(null);
    const [checked, setChecked] = React.useState([]);
    const poid = props?.allStates?.preliminary?.poid
    const [tableKey, setTableKey] = React.useState(0);
    const [labels, setLabels] = React.useState({});
    const callHome = props.callHome;

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
        return isValidated();
    },
    sendState: () => {
        console.log('step3 sendState');
        return sendState();
    },
    resetState: () => {
        console.log('step3 resetState');
        setPurchaseOrder(null);
    },
    state: {
        manualStatus,
    },
  }));
  const sendState = () => {
    return {
      manualStatus,
    };
  };
  const isValidated = () => {
    return true;
  };

  useEffect(()=>{
      if(props.currentStep === 2) {
          console.log('Entering step2... going to go get PO')
          console.log(props);
          getPurchaseOrder();
      }
  }, [props.currentStep]);

    const handleCancel = ()=> {
        callHome('step2','cancel');
    }

    const getPurchaseOrder = async () => {
        console.log('OFFICIAL: checkShippingLabel');

        new PrintStationService().getPurchaseOrder(props.allStates.preliminary.poiid)
            .then((data) => setPurchaseOrder(data.purchase_order))
            .catch((error) => console.log(error));
    }

    const printShippingLabel = (data) => {
        new PrinterService().printShippingLabel(data)
    }

    const reprintShippingLabel = (data) => {
        console.log("Reprinting", data);
        printShippingLabel(data);
    }

    const createShipment = async () => {
        console.log('OFFICIAL: checkShippingLabel');

        new PrintStationService().createShipment(poid, checked)
            .then((response) => {
                if('error' in response) {
                    alert(response.error);
                    callHome('step3','failure');
                } else {
                    console.log('shipment created:')
                    console.log(response);
                    printShippingLabel(response.data.labels);
                    setTableKey(tableKey+1);
                    setChecked([]);
                    setPurchaseOrder(response.data.purchase_order);
                    setLabels((response.data.labels));
                }
            })
            .catch((error) => console.log(error));
    }

  const handleShipButton = (e) => {
        console.log(checked);
        createShipment();
  }

  const handleDone = ()=> {
      callHome('step3','complete')
  }

  if(purchaseOrder === undefined || purchaseOrder === null) {
      return (
          <></>
      )
  }

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={12}>
          <Card>
              <CardHeader color="primary" text>
                  <CardText color="primary" >
                      <h4 className={classes.cardTitleWhite}>Purchase Order</h4>
                        <div> <b>Ship Method:</b> {purchaseOrder.ship_method}</div>
                        <div><b>Order Date:</b> {purchaseOrder.orig_order_date}</div>
                        <div><b>Exp Ship Date:</b> {purchaseOrder.ship_exp}</div>
                  </CardText>
              </CardHeader>
              <CardBody>
                  <GridContainer>
                      <GridItem xs={6} sm={6}>
                      <GridContainer >
                          {purchaseOrder.items.length > 0 ?
                              <>
                              <GridItem xs={12} sm={12} className={classes.center}>
                                  <h4 className={classes.center}>Pending</h4>
                              </GridItem>
                              <GridItem xs={12} sm={12}>
                                  <Card className={classes.tableStyle} style={{padding: "10px 16px", marginTop: '0px'}}>
                                      <Table
                                          key={tableKey}
                                          tableHead={[
                                              "",
                                              "Item Code",
                                              "Description",
                                              "Qty",
                                          ]}
                                          tableData={
                                              purchaseOrder.items.map((item, key) => {
                                                  return ([
                                                      <Checkbox
                                                          key={key}
                                                          defaultChecked={false}
                                                          className={classes.colCheckBox}
                                                          tabIndex={-1}
                                                          onClick={() => handleToggle(item.id)}
                                                          checkedIcon={<Check className={classes.checkedIcon} />}
                                                          icon={<Check className={classes.uncheckedIcon} />}
                                                          classes={{
                                                              checked: classes.checked,
                                                          }}
                                                      />,
                                                      item.item_code,
                                                      item.sku,
                                                      item.quantity
                                                  ])
                                              })
                                          }
                                      />
                                      <div className={classes.center}>
                                          <Button color="primary"
                                                  size="sm"
                                                  style={{width: '100px'}}
                                                  onClick={handleShipButton}
                                          >Ship</Button>
                                      </div>
                                  </Card>
                              </GridItem>
                              </>
                              :
                              <></>
                          }
                      </GridContainer>
                  </GridItem>
                      <GridItem xs={6} sm={6}>
                          <GridContainer>
                              <GridItem xs={12} sm={12} className={classes.center}>
                                  <h4 className={classes.center}>Shipped</h4>
                              </GridItem>
                              {purchaseOrder.shipments.length > 0 ?
                                  <GridItem xs={12} sm={12}>
                                      {purchaseOrder.shipments.map((shipment, shipmentKey) => {
                                          return (
                                              <ShipmentPane
                                                  message= {"Tracking Number: "+shipment.tracking_number}
                                                  list =   {shipment.items}
                                                  shippingLabel = {shipment.label_url}
                                                  key={shipmentKey}
                                                  icon={Check}
                                                  color="primary"
                                                  reprint={reprintShippingLabel}
                                              />
                                          )
                                      })}
                                  </GridItem>
                                  :
                                  <GridItem xs={12} sm={12} className={classes.center}>
                                    <h4>None</h4>
                                  </GridItem>
                              }
                              <GridItem xs={12} sm={12} className={classes.center}>
                                  <Muted className={classes.center}>Available labels: {purchaseOrder.available_labels}</Muted>
                              </GridItem>
                          </GridContainer>
                      </GridItem>
                  </GridContainer>
                  <div className={classes.doneBtn}>
                      <Button autoFocus
                              type="submit"
                              color="primary"
                              onClick={handleDone}
                      >Done</Button>
                  </div>
              </CardBody>
          </Card>

      </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className={classes.cancelLinkCont}>
                <Link onClick={handleCancel}>
                    Cancel and Return
                </Link>
            </div>
        </GridItem>
    </GridContainer>
    );
});

Step3.displayName = "Step3";

export default Step3;
