import React, {useMemo} from "react";
import ChartistGraph from "react-chartist";
import Legend from "chartist-plugin-legend";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import '../../assets/scss/chartistLegend.scss'
import {makeStyles} from "@material-ui/core/styles";
import LoadingSpinner from "../LoadingSpinner";
import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css';


const styles = {
  notificationText: {
    color: "#999",
    marginLeft: "45px"
  },
  lineChartContainer: {
    position: "relative",
    minHeight: "250px"
  },
  hideChartPoints: {
    '& .ct-point': {
      stroke: "transparent!important"
    }
  }
}

const useStyles = makeStyles(styles);

function LineGraph({data, displayMode, dateRange, loading}) {

  let [start, end] = dateRange.map(monthDay => new Date(monthDay))
  let type = "Line"
  const classes = useStyles();

  const monthLabels = () => {
    let labels = []
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let wrapsOverYear = start.getFullYear() !== end.getFullYear()
    let loopEndLimit = wrapsOverYear ? end.getMonth() + 12 : end.getMonth()
    for(let i = start.getMonth(); i <= loopEndLimit; i++){
      labels.push(months[i%12])
    }
    return labels
  }

  const weekLabels = (days) => {
    let labels = []
    days.forEach((day, index) => {
      if ((index + 1) % 7 == 1) {
        labels.push(day)
        return
      }
      if ((index + 1) % 7 == 0 || index + 1 == days.length) {
        labels[labels.length - 1] = labels[labels.length - 1] + "-" + day
      }
    })
    return labels
  }

  const labels = useMemo(() => {
    let all_days = []
    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      let newDate = new Date(dt)
      all_days.push(`${newDate.getMonth() + 1}/${newDate.getDate()}`);
    }
    if (displayMode == "months") return monthLabels()
    if (displayMode == "weeks") return weekLabels(all_days)
    return all_days;
  }, [data, displayMode])

  const seriesWithTooltips = () => {
    let dates = labels
    let rawSeries = data.map(ele => ele.value)
    return rawSeries.map(singleSeries => {
      return singleSeries.map((dayData, date) => {
        return {meta: dates[date], value: dayData}
      })
    })
  }

  const dataForChart = () => {
    return {
      labels: labels,
      series: seriesWithTooltips(),
    }
  }

  const options = () => {
    return {
      axisX: {
        labelInterpolationFnc: (value, index) => {
          let numOfXAxisLabels = 10
          let moduloKey = Math.ceil(data[0].value.length / numOfXAxisLabels)
          return index % moduloKey === 0 || displayMode != "days" ? value : null;
        }
      },
      height: "250px",
      plugins: [
        Legend({
          position: 'bottom',
          legendNames: data.map((ele) => ele.name)
        }),
        ChartistTooltip()
      ],
    }
  }

  const notification = () => {
    if (!loading && data.length == 0) {
      return <p>No data exists with the given filters.</p>
    }
  }

  const conditionalClasses = () => {
    let newClasses = ""
    if (data[0].value.length > 35) {
      newClasses += (" " + classes.hideChartPoints)
    }
    return newClasses
  }

  const currentDisplay = () => {
    if (data && data.length > 0) {
      return <ChartistGraph className={"ct-chart" + conditionalClasses()} type={type} options={options()}
                            data={dataForChart()}/>
    }
  }

  return (
    <div className={classes.lineChartContainer}>
      <LoadingSpinner loading={loading}/>
      {currentDisplay()}
      {notification()}
    </div>
  )
}

export default LineGraph