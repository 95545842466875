import React, {useState} from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function SelectItemCount({classes, value, onChange, onSubmit}) {

    const [showSelect, setShowSelect] = useState(null)
    const onKeyDown = async (event) => {
      if(event.key === 'Enter'){
        setShowSelect(false)
        await onSubmit(event)
        await setShowSelect(null)
      }
    }

    return (
        <div onKeyDown={onKeyDown} key={showSelect} style={{marginTop: "16px"}} >
            <Select
                MenuProps={{
                    className: classes.selectMenu,
                }}
                classes={{
                    select: classes.select,
                }}
                value={value}
                onChange={onChange}
                inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                }}
                style={{width: "100%"}}
            >
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value="all"
                >
                    Any
                </MenuItem>
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value="single"
                >
                    Single Items
                </MenuItem>
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value="multi"
                >
                    Multiple Items
                </MenuItem>
            </Select>
        </div>
    )
}

 // statusSelect();