import request from 'services/Service.js';
import { BASE_OMS_JOB_API } from 'variables/api_urls';

export default class OmsJobService {
  constructor() {
    this.resource_url = BASE_OMS_JOB_API;
  }

  kickoffRegenerateFedexLabelsJob() {
    return request({
      method: 'post',
      url: `${this.resource_url}/kickoff_regenerate_fedex_label_job`
    })
  }

  //todo fix this response email
  kickoffPackingListGenerationJob(ids) {
    return request({
      method: 'post',
      data: {ids: ids, response_email: 'asdfsfdsd'},
      url: `${this.resource_url}/kickoff_packing_list_generation_job`
    })
  }

  reRipImage(purchase_order_item_id) {
    return request({
      method: 'post',
      url: `${this.resource_url}/${purchase_order_item_id}/re_rip_image`
    })
  }
}
