import { BASE_BULK_PACKING_SLIP_API } from 'variables/api_urls';
import request from 'services/Service.js';

export default class BulkPackingSlipService {
    constructor() {
        this.resource_url = `${BASE_BULK_PACKING_SLIP_API}`;
    }

    retrieve(query) {
      return request({
        method: 'get',
        url: `${this.resource_url}?page=${query["page"]}&per_page=${query["per_page"]}`
      })
    }

    getPDF(id) {
        return request({
            method: 'get',
            url: `${this.resource_url}/${id}/pdf_status`
        })
    }
}