import qz from 'qz-tray'
import PrintStationService from "./PrintStationService";
export default class QzPrintService {
    constructor() {
        console.log("In QZ print constructor");
    }

    setupPrintRequest() {
        console.log('Setting up certificate promise')
        qz.security.setCertificatePromise(function (resolve, reject) {
            console.log('Getting digital certificate');
            new PrintStationService().getQzCertificate()
                .then((data) => {
                    resolve(data['cert'])
                })
                .catch((error) => {
                    reject(error);
                })
        });
        qz.security.setSignatureAlgorithm('SHA512');

        console.log('Setting up signature promise')
        qz.security.setSignaturePromise(function (toSign) {
            return function (resolve, reject) {
                console.log('Signing print request: ' + toSign);
                new PrintStationService().getQzSignature(toSign)
                    .then((data) => {
                        console.log('yay');
                        console.log(data);
                        resolve(data['signature'])
                    })
                    .catch((error) => {
                        reject(error)
                    })
            };
        });
    }
}