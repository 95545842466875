import React from "react";
import { Link } from "react-router-dom";
export default () => (
    <div className="vw-100 vh-100 primary-color d-flex align-items-center justify-content-center">
        <div className="jumbotron jumbotron-fluid bg-transparent">
            <div className="container secondary-color">
                <h1 className="display-4">Manufacturing Portal</h1>
                <p className="lead">
                    Getting things done...
                </p>
                <hr className="my-4" />
                <Link
                    to="/orders"
                    className="btn btn-lg custom-button"
                    role="button"
                >
                    Listing
                </Link>
                &nbsp;
                <Link
                    to="/admin/dashboard"
                    className="btn btn-lg custom-button"
                    role="button"
                >
                    Template Dashboard
                </Link>
                &nbsp;
                <Link
                    to="/auth/login-page"
                    className="btn btn-lg custom-button"
                    role="button"
                >
                    Template Login
                </Link>
                &nbsp;
                <Link
                    to="/mp_auth/login-page"
                    className="btn btn-lg custom-button"
                    role="button"
                >
                    MP Login
                </Link>
            </div>
        </div>
    </div>
);