import request from 'services/Service.js';
import { BASE_DASHBOARD_API } from 'variables/api_urls';

export default class DashboardService {
    constructor() {
        this.retrieve_endpoint = `${BASE_DASHBOARD_API}`;
    }

    retrieve(query_string) {
        console.log("getting the data")
        console.log(query_string)
        return request({
            method: 'get',
            url: `${this.retrieve_endpoint}/shipping_summary?${query_string}`
        })
    }
}