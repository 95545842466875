import QzPrint, { printFileToQZ, printToQZ } from "app_components/PrintStation/QzPrint";
import DymoPrint, { printBarcodeLabel, getDymoPrinter } from "app_components/PrintStation/DymoPrint";

export default class PrinterService {
    constructor() {
        console.log("In printer constructor");
    }

    printShippingLabel(data) {
        console.log("In Printer Service ZZZ");
        console.log(data);

        if (data.ucc_128) {
            printToQZ(data.ucc_128)
        }

        if (data.shipping_label) {
            if (data.shipping_label.includes('http')) {
                printFileToQZ(data.shipping_label);
            } else {
                printToQZ(data.shipping_label);
            }
        }
    }

    printUPC(upc) {
        console.log("In Printer Service");
        console.log("Printing UPC:"+upc);
        var printer = getDymoPrinter();

        if (printer == "") {
            alert("No UPC printer found.")
        } else {
            printBarcodeLabel(upc, printer);
        }
    }

    printPackingList(url) {

    }
}