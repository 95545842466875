import React, {Component} from "react";
import ChartistGraph from "react-chartist";
import '../../assets/scss/chartistLegend.scss'
import { makeStyles } from "@material-ui/core/styles";
import Legend from "chartist-plugin-legend";
import LoadingSpinner from "../LoadingSpinner";

const styles = {
    customChart: {
        textAlign: "center",
        '& .ct-legend': {
            width: '100%!important',
            padding: '0!important',
            top: '250px!important',
            '& li:first-child': {
                marginLeft: '0'
            }
        },
        '& .ct-series-a .ct-slice-pie': {
            fill: '#f05b4f!important'
        },
        '& .ct-series-0:before': {
            backgroundColor: '#f05b4f!important',
            borderColor: '#f05b4f!important',
        },
        '& .ct-series-b .ct-slice-pie': {
            fill: '#f4c63d!important'
        },
        '& .ct-series-1:before': {
            backgroundColor: '#f4c63d!important',
            borderColor: '#f4c63d!important',
        },
        '& .ct-series-c .ct-slice-pie': {
            fill: '#59922b!important'
        },
        '& .ct-series-2:before': {
            backgroundColor: '#59922b!important',
            borderColor: '#59922b!important',
        },
    },
    pieChartContainer: {
        position: "relative",
        minHeight: "240px"
    }
}
const useStyles = makeStyles(styles);

function PieChart({late, today, tomorrow, loading}) {
    const classes = useStyles();

    const data = () => {
        return {
            series: [
                {
                    value: late,
                    name: `Late (${late})`,
                },
                {
                    value: today,
                    name: `Due today (${today})`,
                },
                {
                    value: tomorrow,
                    name: `Due tomorrow (${tomorrow})`,
                }
            ]
        }
    }

    let options = {
        height: "240px",
        donut: false,
        showLabel: false,
        plugins: [
            Legend(),
        ],
    }

    let type = "Pie"

    const displayChart = () => {
        if(!loading){
            return <ChartistGraph className={"ct-chart" + " " + classes.customChart} type={type} data={data()} options={options} />
        }
    }

    return (
        <div className={classes.pieChartContainer}>
            <LoadingSpinner loading={loading}/>
            {displayChart()}
        </div>
    )
}

export default PieChart
