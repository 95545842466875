import React, {useEffect} from "react";

// @material-ui/icons
import Search from "@material-ui/icons/Search";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";

// app services
import PrinterService from "services/PrinterService";
import PrintStationService from "services/PrintStationService";
import ErrorService from "../../services/ErrorService";


const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  center: {
    textAlign: "center",
  },
  stepOneForm: {
    marginBottom: "2.5em",
    display: "flex",
    alignItems: "baseline",
    '& > div': {
      flexGrow: "1"
    },
    '& button': {
      width: "80px",
      marginLeft: "15px",
    },
  }
};

const useStyles = makeStyles(styles);

const LabelStep1 = React.forwardRef((props, ref) => {

  const callHome = props.callHome;

  const classes = useStyles();

  const [splitOrderStatus, setSplitOrderStatus] = React.useState({status: -1});
  const [poiid, setPoiid] = React.useState("");
  const [poid, setPoid] = React.useState("");
  const [shippingLabelStatus,setShippingLabelStatus ] = React.useState('unknown')

  React.useImperativeHandle(ref, () => ({
    sendState: () => {
      console.log('step1 sendState');
      console.log(poid);
      return sendState();
    },
    resetState: () => {
      console.log('step1 resetState');
      setPoid('');
      setPoiid('');
      setSplitOrderStatus({status: -1});
      setShippingLabelStatus('unknown')
    },
    state: {
      poid,
      poiid,
      shippingLabelStatus,
      splitOrderStatus,
    },
  }));

  const sendState = () => {
    return {
      poid,
      poiid,
      shippingLabelStatus,
      splitOrderStatus
    };
  };

  const printShippingLabel = (data) => {
    new PrinterService().printShippingLabel(data)
  }

  const checkShippingLabel = async () => {
    console.log('OFFICIAL: checkShippingLabel');
    new PrintStationService().checkShippingLabel(poiid)
        .then((data) => {
          if(data.status == 'error'){
            new ErrorService().report(data)
            return;
          }
          setPoid(data.purchase_order_id)
          if(data.manual_shipment)
            setShippingLabelStatus('manual')
          else if (data.value)
            setShippingLabelStatus('exists')
          else
            setShippingLabelStatus('none');
        })
        .catch((error) => {
          console.log(error);
        });
  }

  const shipPO = async (flag) => {
    const addFlag = flag ? 'add_label=true' : 'add_label=false'
    new PrintStationService().shipPO(poiid, addFlag)
        .then((data) => {
          printShippingLabel(data);
          callHome('step1','label_printed');
        })
        .catch((error) => {
          console.log(error);
        });
  }

  const checkSplitShipment = async () => {
    console.log('OFFICIAL: checkSplitShipment');
    new PrintStationService().checkSplitOrder(poiid)
        .then((data) => {
          setSplitOrderStatus(data.response)
        })
        .catch((error) => {
          console.log(error);
        });
  }

  useEffect(()=>{
    switch(shippingLabelStatus) {
      case 'manual':
        callHome('step1', 'manual')
        break;
      case'exists':
        // alert('Already have a label. Reprint?')
        callHome('step1', 'label_exists')
        break;
      case 'none':
        checkSplitShipment();
        break;
      case 'unknown':
        break;
      default:
        console.log('Uncaught switch value for shippingLabelStatus: '+shippingLabelStatus);
    }
  }, [shippingLabelStatus])

  // React.useEffect(()=> {
  //
  // }, [props.currentStateCounter])
  //

  useEffect(() => {
    //todo change splitorderstatus.status to compare with constants like SPLIT NOT_SPLIT

    switch(splitOrderStatus.status) {
      case 1:
        shipPO(false);
        break;
      case 2:
      case 3:
        callHome('step1', 'split_order_status')
        break;
      default:
    }
  }, [splitOrderStatus])


  const handleSubmit = (event) => {
    event.preventDefault();
    checkShippingLabel();
  };

  return (
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={10}>
          <h4 className={classes.infoText}>
            Please scan the BARCODE on the top right hand corner of the packing slip to get started:
          </h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <form onSubmit={handleSubmit} className={classes.stepOneForm}>
              <CustomInput
                  labelText={
                    <span>Purchase Order Barcode</span>
                  }
                  id="poiid"
                  inputProps={{
                    autoFocus: true,
                    value: poiid,
                    onChange: (event) => {
                      setPoiid(event.target.value);
                    }
                  }}
              />
              <Button color="primary" type="submit">
                Submit
              </Button>
          </form>

          <Muted>
            <p>Scan the bar code from the DENY Designs packing slip in order to print the shipping label on a Zebra or OKI label printer (other raw printers may be configured to work).</p>
            <p>If you are having trouble printing please be sure the following conditions are met:</p>
            <ul>
              <li>
                QZ Tray is loaded and running. (https://qz.io/)
              </li>
              <li>
                QZ Tray Advanced Setting -> Block Anonymous Requests is unchecked
              </li>
              <li>
                If the 'Allow/Block Untrusted Website' message appears, check the 'Remember the decision' checkbox and click 'Allow'
              </li>
              <li>
                The printer is connected and set as the default printer.
              </li>
            </ul>
          </Muted>
        </GridItem>
      </GridContainer>
  );
});

LabelStep1.displayName = "Step1";

export default LabelStep1;