import React, {useEffect, useMemo, useRef, useState} from "react";
import {Link} from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import Datetime from "react-datetime";

import moment from "moment"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Print from "@material-ui/icons/Print";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Snackbar from "components/Snackbar/Snackbar.js";


// core styles
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

// app components
import PurchaseOrderTable from "app_components/PurchaseOrders/PurchaseOrderTable.js";
import SelectItemCount from "app_components/PurchaseOrders/SelectItemCount.js"
import SelectCustomers from "app_components/PurchaseOrders/SelectCustomers"
import MultiSelect from "app_components/MultiSelect"
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// app services
import PurchaseOrderService from "services/PurchaseOrderService";

customSelectStyle["selectMenuItem"]["&:hover"] = {
    backgroundColor: "rgba(0,0,0,0.1)",
    boxShadow: "none",
    color: "inherit",
}
customSelectStyle["selectMenuItemSelected"]["color"] = "white!important" // To overwrite the hover effect color

const styles = {
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    pageTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center",
    },
    btnCol: {
        position: "relative",
        float: "right"
    },
    openBtn: {
        marginRight: "5px",
        padding: "5px!important",
        '& svg': {
            margin: "0"
        }
    },
    outerFilterContainer: {
        marginBottom: "30px",
        '& > div > div': {
            margin: "0!important",
        }
    },
    innerFilterContainer: {
        '& > div': {
            display: "flex",
            alignItems: "flex-end",
        },
        '& input, & label': {
            fontSize: "12px!important",
            textTransform: "uppercase",
            color: "#555!important"
        },
    },
    searchInput: {
        '& > div': {
            marginTop: "-27px",
            marginBottom: "0",
        },
        '& label': {
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
        },
        '& input': {
            textTransform: "none",
        },
    },
    buttonColumn: {
        paddingLeft: '0!important',
        display: "flex",
        alignItems: "center",
    },
    dateColumn: {
        '& > div': {
            marginTop: "1px", // To fix height discrepency in inputs from different libraries
            marginBottom: "-1px", // To fix height discrepency in inputs from different libraries
        }
    },
    permanentlyRaisedLabel: {
        marginTop: "-16px",
    },
    inputStyle: {
        "& .MuiInput-underline": {
            "&:before": {
                borderBottom: "1px solid #d2d2d2"
            },
            "&:after": {
                borderBottom: "2px solid #33CCCC"
            }
        }
    },
    disabledButton: {
        backgroundColor: "#D5D5D5!important",
        boxShadow: "none",
    },
    indexAndCheckboxCol: {
        display: "flex",
        alignItems: "center",
        '& p': {
            margin: "0",
            width: "45px",
            padding: "0 10px",
        }
    },
    disabledCheckBox: {
        '& > span:first-child': {
            background: "#D5D5D5!important",
        },
        '& svg': {
            border: "1px solid rgba(0,0,0,0.3)!important"
        }
    }
};

const useStyles = makeStyles(styles);
let checked = []

export default function List(props) {
    const initialQueryState = {statuses: 'OPEN', item_count_filter: 'all'}
    const searchParams = new URLSearchParams(props.location.search);
    const parmObject =  Object.fromEntries([...searchParams])
    const [keyBase, setKeyBase] = useState(0)

    console.log('loading initialQueryState')
    let initialStatuses = ['OPEN']

    let initialPage = 1;
    if ('page' in parmObject) {
        console.log('found page!!!!! in parm', parmObject.page)
        initialPage = parmObject.page
    }

    if ('statuses' in parmObject) {
        console.log('found status in parm', parmObject.statuses)
        initialQueryState.statuses = parmObject.statuses
        initialStatuses = initialQueryState.statuses.split(',');
    }

    let initialDateGroup=''
    if ('date_group' in parmObject) {
        initialQueryState.date_group = parmObject.date_group
        initialDateGroup = parmObject.date_group
    }

    let initialCategorySet = []
    if ('categories' in parmObject) {
        initialQueryState.categories = parmObject.categories
        initialCategorySet = parmObject.categories.split(',')
    }

    let initialItemCountFilter = 'all'
    if ('item_count_filter' in parmObject) {
        initialQueryState.item_count_filter = parmObject.item_count_filter
        initialItemCountFilter = parmObject.item_count_filter
    }

    let initialStartDate=''
    if ('start_date' in parmObject) {
        initialQueryState.start_date = parmObject.start_date
        initialStartDate = moment(parmObject.start_date, 'MM-DD-YYYY');
    }

    let initialEndDate=''
    if ('end_date' in parmObject) {
        initialQueryState.end_date = parmObject.end_date
        initialEndDate = moment(parmObject.end_date, 'MM-DD-YYYY');
    }

    let initialSearchString = ''
    if ('search_string' in parmObject) {
        initialQueryState.search_string = parmObject.search_string
        initialSearchString = parmObject.search_string
    }

    let initialSortBy = 'order_date';
    if ('sort_by' in parmObject) {
        console.log('found sort_by in parm', parmObject.sort_by)
        initialQueryState.sort_by = parmObject.sort_by
        initialSortBy = parmObject.sort_by
    }

    let initialSortOrder = 'desc';
    if ('sort_order' in parmObject) {
        console.log('found sort_order in parm', parmObject.sort_order)
        initialQueryState.sort_order = parmObject.sort_order
        initialSortOrder = parmObject.sort_order
    }

    let initialCustomerSet = []
    if ('customer_no' in parmObject) {
        console.log('found customer_no in parm', parmObject.customer_no)
        initialQueryState.customer_no = parmObject.customer_no
        initialCustomerSet = parmObject.customer_no.split(',')
        console.log(initialCustomerSet)
    }

    let initialSkuSet = []
    if ('skus' in parmObject) {
        console.log('found skus in parm', parmObject.skus)
        initialQueryState.skus = parmObject.skus
        initialSkuSet = parmObject.skus.split(',')
        console.log(initialSkuSet)
    }



    const csvLink = useRef();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(initialPage);
    const [poCount, setPoCount] = useState(0);
    const [poData, setPoData] = useState([]);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(0)
    const [pageSize, setPageSize] = useState(JSON.parse(localStorage.getItem('number_of_rows')) || 10);
    const [queryParms, setQueryParms] = useState(initialQueryState);
    const [sortDirection, setSortDirection] = useState(initialSortOrder)
    const [sortBy, setSortBy] = useState(initialSortBy);
    const [allIds, setAllIds] = useState([]);
    const [exportFileName, setExportFileName] = useState('')
    const [showNotification, setShowNotification] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('Placeholder Message.')
    const [notificationType, setNotificationType] = useState('success')
    const [checkedList, setCheckedList] = useState(checked)



    const changePageSize = (rows) => {
        setPage(1);
        localStorage.setItem('number_of_rows', JSON.stringify(rows))
        setPageSize(rows);
    }

    const handleToggle = (e, poID) => {
        if(e.shiftKey && checked.length >= 1) {
            checked = [...checked, poID]
            let indexOflastTwo = checked.slice(-2).map(i => allIds.indexOf(i)).sort()
            let idsInRange = allIds.slice(indexOflastTwo[0]+1, indexOflastTwo[1])
            checked = [...new Set([...checked, ...idsInRange])]
        } else {
            checked = checked.includes(poID) ? checked.filter(i => i !== poID) : [...checked, poID]
        }
        setCheckedList(checked)
    }

    const convertData = (inbound) => {
        return inbound.map((prop, i) => {
            return {
                id: prop['id'],
                checkbox: (
                  <div className={classes.indexAndCheckboxCol}>
                      <p>{(page-1)*pageSize + 1 + i}</p>
                      { prop['packing_list_available'] ? (
                        <Checkbox
                          key={i}
                          checked={checkedList.includes(prop['id'])}
                          className={classes.positionAbsolute}
                          tabIndex={-1}
                          onClick={(e) => handleToggle(e, prop['id'])}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                          }}
                        />
                      ) : (
                        <span title="Packing List is currently not available.">
                        <Checkbox
                          key={i}
                          className={classes.positionAbsolute}
                          tabIndex={-1}
                          disabled
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                              root: classes.checkRoot + " " + classes.disabledCheckBox,
                          }}
                        />
                        </span>
                      )}
                  </div>
                ),
                exported_order_id: prop['exported_order_id'],
                customer_name: prop['customer_name'],
                status_name: prop['status_name'],
                order_date: prop['order_date'],
                ship_exp: prop['ship_exp'],
                skus: prop['skus'],
                item_count: prop['item_count'],
                actions: (
                    // we've added some custom button actions
                    <>
                        <div className={classes.btnCol}>
                            { prop['packing_list_available'] ? (
                              <Button
                                component = {Link}
                                target="_blank"
                                to={`/purchase_orders/${prop['id']}/packing_list_${prop['id']}.pdf`}
                                color="primary"
                                className={"edit " + classes.openBtn}
                                size="sm"
                              >
                                  <Print/>
                              </Button>
                            ) : (
                              <Button
                                component = {Link}
                                disabled
                                to="#"
                                color="primary"
                                className={"edit" + ` ${classes.openBtn} ${classes.disabledButton}`}
                                size="sm"
                              >
                                  <Print/>
                              </Button>
                            )}

                            <Button
                                component = {Link}
                                to={"/admin/purchase-order-detail/"+prop['id']}
                                color="primary"
                                className={"edit " + classes.openBtn}
                                size="sm"
                            >
                                <OpenInNew/>
                            </Button>
                        </div>
                    </>

                ),
            }
        })
    }

    const getPurchaseOrderData = async (params) => {
        console.log('OFFICIAL: getPurchaseOrderData');
        console.log(params);

        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

        console.log('querystring', queryString)
        window.history.replaceState('', 'New Page Title', '/admin/purchase-orders?' + queryString);

        setLoading(true);
        new PurchaseOrderService().search(queryString)
            .then((data) => {
                setPoData(data['purchase_orders']);
                setTotalNumberOfPages(data['total_pages']);
                setPoCount(data['total_size']);
                setAllIds(data['all_ids'])
                setLoading(false);
            })
            .catch(error => console.log(error))
    }

    const [csvData, setCsvData] = useState('');
    const getCSV = async (params) => {
        setLoading(true)
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        new PurchaseOrderService().retrieveCSV(queryString)
            .then(async (data) => {
                if (data.result == 'error') {
                    setNotificationType('danger')
                    setNotificationMessage(data["message"])
                    setShowNotification(true)
                } else {
                    setCsvData(data.csv);
                    let d = new Date()
                    let datestring = d.getFullYear() + ("0" + (d.getMonth() + 1)).slice(-2) + ("0" + d.getDate()).slice(-2) + ("0" + d.getHours()).slice(-2) + ("0" + d.getMinutes()).slice(-2);
                    await setExportFileName(`po_export_${datestring}.csv`)
                    // Ugly but necessary - react-csv's async functionality is famously broken
                    // This is the standard work-around
                    csvLink.current.link.click();
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Sku Options state and hydration
    const [skus, setSkus] = useState(initialSkuSet);
    const [skuOptions,setSkuOptions] = useState([]);
    const getSkuOptions = () => {
        new PurchaseOrderService().retrieveSkuOptions()
          .then((data) => {
              setSkuOptions(data['sku_options']);
          })
          .catch(error => console.log(error))
    }

    const handleSkuChange = (newSkuList) => {
        console.log("new skus! ", newSkuList)
        setSkus(newSkuList);
        registerParmChange('skus', newSkuList);
    }

    // Category Options state and hydration
    const [categories, setCategories] = useState(initialCategorySet);
    const [categoryOptions, setCategoryOptions] = useState([]);

    const getCategoryOptions = () => {
        new PurchaseOrderService().retrieveCategoryOptions()
          .then((data) => {
              setCategoryOptions(data['cutlist_category_options']);
          })
          .catch(error => console.log(error))
    }

    const handleCategoryChange = (newCategoryList) => {
        console.log('register_parm change for cats')
        setCategories(newCategoryList);
        registerParmChange('categories', newCategoryList);
    }

    // Status Options
    const statusOptions = [
      ["Any", "ANY"],
      ["Open", "OPEN"],
      ["Exported", "EXPORTED"],
      ["Shipped", "SHIPPED"],
      ["Invoiced", "INVOICED"],
      ["Processing", "PROCESSING"],
      ["Packing", "PACKING"],
      ["Shipping error", "SHIPPING_ERROR"]
    ]

    const [statuses, setStatuses] = useState(initialStatuses)

    const handleStatusChange = (newStatusList) => {
        setStatuses(newStatusList);
        registerParmChange('statuses', newStatusList);
    }

    useEffect(() => {
        console.log('useEffect for "page, pageSize, sortBy, sortDirection"')
        console.log(page, pageSize, sortBy, sortDirection);
        console.log('queryParms', queryParms);


        let parms = {
            ...queryParms,
            page: page,
            per_page: pageSize,

        };

        console.log('parms', parms)
        getPurchaseOrderData(parms);
    }, [page, pageSize, sortBy, sortDirection])

    useEffect( () => {
        getSkuOptions()
        getCategoryOptions()
    }, [])

    const canPreviousPage = () => {
        if(page < 2) {
            return false;
        } else {
            return true;
        }
    }

    const canNextPage = () => {
        if(page == (totalNumberOfPages)) {
            return false;
        } else {
            return true;
        }
    }

    const handleSearchSubmit = ((e)=>{
        e.preventDefault()
        setPage(1);
        let parms = {
            page: 1,
            per_page: pageSize,
            ...queryParms
        };
        getPurchaseOrderData(parms);
    });

    const exportHandler = (e) => {
        getCSV(queryParms);
    }

    const handleStartChange = (e) => {
        if(moment.isMoment(e)){
            setStartDate(e);
            registerParmChange('start_date', e.format("MM/DD/YYYY"));
        } else {
            setStartDate(initialStartDate);
            let {start_date, ...newQueryParms} = queryParms
            setQueryParms(newQueryParms)
            setDateKey(dateKey + 1)
        }
    }

    const handleEndChange = (e) => {
        if(moment.isMoment(e)) {
            setEndDate(e);
            registerParmChange('end_date', e.format("MM/DD/YYYY"));
        } else {
            setEndDate(initialEndDate);
            let {end_date, ...newQueryParms} = queryParms
            setQueryParms(newQueryParms)
            setDateKey(dateKey + 1)
        }
    }

    const registerParmChange = (key, value) => {
        const updatedParms = queryParms;
        // Checks if string or array is empty and removes from the query if it is
        if (value.length > 0) {
            updatedParms[key] = value;
        } else {
            delete updatedParms[key]
        }
        setQueryParms(updatedParms);
        console.log(updatedParms);
    }

    const [itemCount, setItemCount] = useState(initialItemCountFilter)
    const handleItemCountChange = (e) => {
        setItemCount(e.target.value);
        registerParmChange('item_count_filter', e.target.value);
    }

    const [searchString, setSearchString] = useState(initialSearchString)
    const handleSearchStringChange = (e) => {
        setSearchString(e.target.value);
        registerParmChange('search_string', e.target.value);
    }



    const [customers, setCustomers] = useState(initialCustomerSet);

    const handleCustomerChange = (e, newValue) => {
        let list = newValue.map( ele => ele[1]);
        setCustomers(list);
        registerParmChange('customer_no', list);
    }

    const [dateKey, setDateKey] = useState(1);

    const [startDate, setStartDate] = useState(initialStartDate);

    const [endDate, setEndDate] = useState(initialEndDate);


    const [dateGroup, setDateGroup] = useState(initialDateGroup);

    const handleResetFilter = () => {
        setItemCount("all");
        setStatuses(["OPEN"]);
        // setCustomer("");
        setSkus([]);
        setCategories([]);
        setDateGroup('');
        setCustomers([]);
        setStartDate('');
        setEndDate('');
        setSearchString("");
        setDateKey(dateKey + 1);
        setQueryParms({});
        setPage(1);
        // setSortBy('order_date');
        // setSortDirection('desc')
        // initialSortBy = 'order_date'
        // initialSortOrder = 'desc'
        customSetSortBy([{id: 'order_date', desc: true}])
        setKeyBase(keyBase+1)

        let parms = {
            status: 'OPEN',
            item_count_filter: 'all',
            sort_by: 'order_date',
            sort_order: 'desc',
        };

        setQueryParms(parms);

        getPurchaseOrderData({ ...parms, page: page, per_page: pageSize})
    }


    const customSetSortBy = (parms) => {
        if (parms !== undefined){
            if(parms.length > 0) {
                const {id, desc} = parms[0];
                let requestedDirection = desc ? "desc" : "asc"

                if(id == sortBy && requestedDirection == sortDirection){
                    console.log('no change - doing nothing')
                    return
                }

                setSortBy(id);
                if(desc == true) {
                    setSortDirection('desc');
                    registerParmChange("sort_order", 'desc');
                } else {
                    setSortDirection('asc');
                    registerParmChange("sort_order", 'asc');
                }
                registerParmChange("sort_by", id);
            } else {
                setSortDirection(initialSortOrder);
                registerParmChange("sort_order", initialSortOrder);

                setSortBy(initialSortBy);
                registerParmChange("sort_by", initialSortBy);

            }
            setPage(1);
        }
    };

    const classes = useStyles();
    return (

        <GridContainer justifyContent="center">
            {/*<code>*/}

            {/*    {JSON.stringify(*/}
            {/*        queryParms,*/}
            {/*        null,*/}
            {/*        2*/}
            {/*    )}*/}
            {/*    <br/>*/}
            {/*    status: {status}<br/>*/}
            {/*    start_date: {(startDate instanceof moment) ? startDate.format() : ''}<br/>*/}
            {/*    end_date: {(endDate instanceof moment) ? startDate.format() : ''}<br/>*/}
            {/*    sort_by: {sortBy}<br/>*/}
            {/*    category: {category}<br/>*/}
            {/*    customer_no: {JSON.stringify(customers, null, 2)}*/}
            {/*</code>*/}


            <GridItem xs={12}>
                <Snackbar
                  place="tr"
                  color={notificationType}
                  message={notificationMessage}
                  open={showNotification}
                  closeNotification={() => setShowNotification(false)}
                  close
                />
                <h3 className={classes.pageTitle}>Purchase Orders</h3>
                <Card>
                    <CardHeader icon>
                        <CardIcon color="warning">
                            <Assignment />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={handleSearchSubmit} autoComplete="off">
                            <GridContainer className={classes.outerFilterContainer}>
                                <GridItem xs={12} sm={10} md={11}>
                                    <Card>
                                        <CardHeader color="primary" icon>
                                            <h4 className={classes.cardIconTitle}>Search</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer className={classes.innerFilterContainer}>
                                                <GridItem xs={12} md={4} className={classes.searchInput}>
                                                    <CustomInput
                                                      labelText="ENTER SEARCH TERM"
                                                      id="search_text"

                                                      formControlProps={{
                                                          fullWidth: true,
                                                      }}
                                                      inputProps={{
                                                          type: "text",
                                                          value: searchString,
                                                          onChange: handleSearchStringChange
                                                      }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} md={2} className={classes.dateColumn}>
                                                        <FormControl className={classes.datePicker} fullWidth>
                                                            <Datetime
                                                                key={dateKey}
                                                                value={startDate}
                                                                timeFormat={false}
                                                                inputProps={{ 'data-lpignore': 'true', placeholder: "START DATE", disabled: dateGroup != '' }}
                                                                onChange={handleStartChange}
                                                                closeOnSelect={true}
                                                            />
                                                        </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} md={2} className={classes.dateColumn}>
                                                    <FormControl className={classes.datePicker} fullWidth>
                                                        <Datetime
                                                          key={dateKey}
                                                          disables
                                                          value={endDate}
                                                          timeFormat={false}
                                                          inputProps={{ 'data-lpignore': 'true', placeholder: "END DATE", disabled: dateGroup != '' }}
                                                          onChange={handleEndChange}
                                                          closeOnSelect={true}
                                                        />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} md={2}>
                                                    <FormControl fullWidth className={classes.permanentlyRaisedLabel  + " " + classes.inputStyle}>
                                                        <MultiSelect
                                                          title="Status"
                                                          values={statuses}
                                                          options={statusOptions}
                                                          onChange={handleStatusChange}
                                                          onSubmit={handleSearchSubmit}
                                                        />
                                                    </FormControl>
                                                </GridItem>

                                                <GridItem xs={12} md={2}>
                                                    <FormControl fullWidth className={classes.permanentlyRaisedLabel + " " + classes.inputStyle}>
                                                        <InputLabel
                                                          htmlFor="simple-select"
                                                          className={classes.selectLabel}
                                                        >
                                                            Item Counts
                                                        </InputLabel>
                                                        <SelectItemCount
                                                          classes={classes}
                                                          value={itemCount}
                                                          onChange={handleItemCountChange}
                                                          onSubmit={handleSearchSubmit}
                                                        />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} md={3} >
                                                    <FormControl fullWidth className={classes.inputStyle}>
                                                        <MultiSelect
                                                          title="SKU's"
                                                          values={skus}
                                                          options={skuOptions}
                                                          onChange={handleSkuChange}
                                                          onSubmit={handleSearchSubmit}
                                                        />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} md={3} >
                                                    <FormControl fullWidth className={classes.inputStyle}>
                                                        <MultiSelect
                                                          title="CATEGORY"
                                                          values={categories}
                                                          options={categoryOptions}
                                                          onChange={handleCategoryChange}
                                                          onSubmit={handleSearchSubmit}
                                                        />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} md={6} style={{display: "flex", alignItems: "flex-end"}}>
                                                    <FormControl fullWidth className={classes.inputStyle}>
                                                        <SelectCustomers
                                                          values={customers}
                                                          onChange={handleCustomerChange}
                                                        />
                                                    </FormControl>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridItem>

                                <GridItem xs={12} sm={2} md={1} className={classes.buttonColumn}>
                                    <div style={{width: "100%"}}>
                                        <Button type="submit"
                                                color="primary"
                                                size="sm"
                                                style={{width: '100%'}}
                                        >Search</Button>
                                        <Button color="primary"
                                                size="sm"
                                                style={{width: '100%'}}
                                                onClick={exportHandler}
                                        >Export</Button>
                                        <Button size="sm"
                                                style={{width: '100%'}}
                                                onClick={handleResetFilter}
                                        >Reset</Button>
                                        <CSVLink filename={exportFileName} ref={csvLink} className="hidden" data={csvData}></CSVLink>
                                    </div>

                                </GridItem>
                            </GridContainer>
                        </form>
                        <PurchaseOrderTable
                                hidden={true}
                                key={keyBase}
                                justifyContent="center"
                                columns={[
                                    {
                                        Header: "",
                                        accessor: "checkbox",
                                        disableSortBy: true,
                                    },
                                    {
                                        Header: "PO No",
                                        accessor: "exported_order_id",
                                    },
                                    {
                                        Header: "Customer",
                                        accessor: "customer_name",
                                    },
                                    {
                                        Header: "Status",
                                        accessor: "status_name",
                                    },
                                    {
                                        Header: "Ordered",
                                        accessor: "order_date",
                                    },
                                    {
                                        Header: "Due",
                                        accessor: "ship_exp",
                                    },
                                    {
                                        Header: "SKU\'s",
                                        accessor: "skus",
                                    },
                                    {
                                        Header: "# Items",
                                        accessor: "item_count",
                                    },
                                    {
                                        Header: "",
                                        accessor: "actions",
                                    },
                                ]}
                                data={convertData(poData)}
                                setPage = {setPage}
                                canPreviousPage = {canPreviousPage}
                                canNextPage = {canNextPage}
                                pageNumber={page}
                                numberOfPages={totalNumberOfPages}
                                setNumberOfRows={changePageSize}
                                pageSize={pageSize}
                                poCount={poCount}
                                customSetSortBy={customSetSortBy}
                                selectAll={() => {
                                    checked = allIds
                                    setCheckedList(checked)
                                }}
                                deselectAll={() => {
                                    checked = []
                                    setCheckedList(checked)
                                }}
                                checked={checked}
                                loading={loading}
                                // initialSortBy={initialSortBy}
                                // initialSortOrder={initialSortOrder}
                                initialSortBy={sortBy}
                                initialSortOrder={sortDirection}
                            />

                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
