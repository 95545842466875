import React, {useEffect, useState} from "react";
import Routes from "../routes/Index";
import {BrowserRouter as Router, BrowserRouter, Redirect, Route, Switch, useLocation} from "react-router-dom";
import Orders from "../app_components/Orders";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import LoginPage from "views/Pages/LoginPage.js";
import AuthService from "../services/AuthService";
import { useIdleTimer } from 'react-idle-timer'


export default function App() {
    const [isLoggedIn, setIsLoggedIn] = useState('unknown');
    const [user, setUser] = useState();

    useEffect(()=> {
        getLoginStatus();
    },[])

    const updateStatus = (userStatus) => {

       if(userStatus.logged_in)
           setIsLoggedIn('true')
        else
           setIsLoggedIn('false')

        if(userStatus.logged_in) {
            setUser(userStatus.user);
        }
    }

    const handleOnIdle = event => {
        if(isLoggedIn){
            logOut();
        }
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 60 * 8,
        onIdle: handleOnIdle,
        debounce: 500
    })

    const logOut = () => {
        new AuthService().logout().then((data) => {
            updateStatus(data)
        }).catch((error) => {
            console.log(error)
        });
    }

        const getLoginStatus = (params) => {
        new AuthService().retrieveStatus()
            .then((data) => {
                console.log(data);
                if(data.logged_in) {
                    setUser(data.user);
                    setIsLoggedIn('true');
                } else {
                    setIsLoggedIn('false');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    if(isLoggedIn == 'unknown')
        return(
            <></>
        )
    return (
        <>
            {console.log('status:')}
            {console.log(isLoggedIn)}

            {isLoggedIn === 'true' ?
                <Router>
                    <Switch>
                        <Route path="/orders" exact component={Orders} />
                        <Route path="/admin" render={(props) => <AdminLayout {...props} mpUser={user} updateStatus={updateStatus}/>}/>
                        <Redirect from="/" to="/admin/dashboard" mpUser={user}/>
                    </Switch>
                </Router>
                :
                // <h4>logged out</h4>
                <Router>
                    <Switch>
                        <Route path="/auth/reset-password-page/:token" render={(props) => <AuthLayout {...props} isLoggedIn={updateStatus}/>}  />
                        {/*<Route path="/auth/reset-password-page/:token" render={(props) => <AuthLayout {...props} isLoggedIn={updateStatus}/>}  />*/}
                        <Route path="/auth" render={(props) => <AuthLayout {...props} isLoggedIn={updateStatus}/>}  />
                        <Redirect to="/auth/login-page"/>
                    </Switch>
                </Router>
            }
        </>

    )
}





//
// class App extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isLoggedIn: true,
//             user: {}
//         };
//     }
//
//     componentDidMount() {
//         this.loginStatus()
//     }
//     loginStatus = () => {
//         this.setState({
//                     isLoggedIn: true
//                     // user: data.user
//                 })
//         // axios.get('http://localhost:3001/logged_in',
//         //     {withCredentials: true})
//         //     .then(response => {
//         //         if (response.data.logged_in) {
//         //             this.handleLogin(response)
//         //         } else {
//         //             this.handleLogout()
//         //         }
//         //     })
//         //     .catch(error => console.log('api errors:', error))
//     }
//     // handleLogin = (data) => {
//     //     this.setState({
//     //         isLoggedIn: true,
//     //         user: data.user
//     //     })
//     // }
//     // handleLogout = () => {
//     //     this.setState({
//     //         isLoggedIn: false,
//     //         user: {}
//     //     })
//     // }
//
//     render() {
//         if(this.state.isLoggedIn)
//             return (
//                 <Router>
//                     <Switch>
//                         <Route path="/orders" exact component={Orders} />
//                         <Route path="/auth" component={AuthLayout} />
//                         <Route path="/admin" component={AdminLayout} />
//                         {/*<Route path='/exportcsv' component={() => {*/}
//                         {/*    window.location.href = 'http://localhost:3001/homepage/non_react_route.csv';*/}
//                         {/*    return null;*/}
//                         {/*}}/>            */}
//                         <Redirect from="/" to="/admin/dashboard" />
//                     </Switch>
//                 </Router>
//             );
//         else
//             return(
//                 <h1>Not logged in</h1>
//             )
//     }
// }
// export default App;