import request from 'services/Service.js';
import { BASE_PURCHASE_ORDER_API } from 'variables/api_urls';

export default class PurchaseOrderService {
  constructor() {
    this.search_endpoint = `${BASE_PURCHASE_ORDER_API}`;
    this.retrieve_endpoint = `${BASE_PURCHASE_ORDER_API}`;
    this.sku_options_endpoint = `${BASE_PURCHASE_ORDER_API}/sku_options`;
    this.category_options_endpoint = `${BASE_PURCHASE_ORDER_API}/cutlist_category_options`;
    this.getPDF = `${BASE_PURCHASE_ORDER_API}/getPDF`;
  }

  search(query_string) {
    return request({
      method: 'get',
      url: `${this.search_endpoint}?${query_string}`
    })
  }
  
  retrieve(id) {
    return request({
      method: 'get',
      url: `${this.retrieve_endpoint}/${id}`
    })
  }

  retrieveSkuOptions(query_string) {
    let options = (query_string === undefined) ? '' : `?${query_string}`
    return request({
      method: 'get',
      url: `${this.sku_options_endpoint}${options}`
    })
  }

  retrieveCategoryOptions() {
    return request({
      method: 'get',
      url: `${this.category_options_endpoint}`
    })
  }

  retrieveCSV(query_string) {
    return request({
      method: 'get',
      url: `${this.retrieve_endpoint}.csv?${query_string}`
    })
  }

  printPOs(ids) {
    return request({
      method: 'get',
      // data: {ids: ids},
      responseType: 'blob',
      url: `${this.retrieve_endpoint}.pdf?ids=${ids}`
    })
  }
}
