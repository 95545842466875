import request from 'services/Service.js';
import { BASE_CUTLIST_API } from 'variables/api_urls';

export default class CutlistService {
  constructor() {
    this.retrieve_endpoint = `${BASE_CUTLIST_API}`;
    this.leg_counts_endpoint = `${BASE_CUTLIST_API}/leg_counts`;
  }
  // todo Consider consolidating cutlist methods into 1 with a parameter for format
  retrieve(query_string) {
    return request({
      method: 'get',
      url: `${this.retrieve_endpoint}?${query_string}`
    })
  }

  retrieveCSV(query_string) {
    return request({
      method: 'get',
      url: `${this.retrieve_endpoint}.csv?${query_string}`
    })
  }
// todo Consider consolidating legcount methods into 1 with a parameter for format
  legCounts() {
    return request({
      method: 'get',
      url: `${this.leg_counts_endpoint}`
    })
  }

  legCountsCSV(query_string) {
    return request({
      method: 'get',
      url: `${this.leg_counts_endpoint}.csv`
    })
  }
}
