import React, {useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Clear from "@material-ui/icons/Clear";

// core components
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import BinLocationTable from "app_components/BinLocations/BinLocationTable";
import BinLocationService from "services/BinLocationService";
import LoadingSpinner from "app_components/LoadingSpinner.js";
import PurchaseOrderService from "../../services/PurchaseOrderService";


const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    clearButton: {
        cursor: "pointer",
        color: "#33CCCC"
    },
    pageTitle: {
      color: "#3C4858",
      textDecoration: "none",
      textAlign: "center",
    },
    poLink: {
        color: "#33CCCC"
    }
};

const useStyles = makeStyles(styles);

export default function List() {
    const [binLocations, setBinLocations] = useState([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSizeState] = useState(JSON.parse(localStorage.getItem('number_of_rows_for_bins')) || 20)
    const [pageTotal, setPageTotal] = useState(1)
    const [loading, setLoading] = useState(false)

    const setPageSize = (rows) => {
        localStorage.setItem('number_of_rows_for_bins', JSON.stringify(rows))
        setPageSizeState(rows)
    }

    const paginationProps = {
       page,
       pageSize,
       pageTotal,
       setPage,
       setPageSize
    }

    let binLocationService = new BinLocationService()

    const getBinLocationData = async (params) => {
        console.log('OFFICIAL: getBinLocationData');
        binLocationService.search(params)
            .then((data) => {
                console.log("Setting data");
                setBinLocations(data['bin_locations'])
                setPageTotal(data['total_pages'])
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const clearBin = id => {
        binLocationService
          .delete(id)
          .then(setBinLocations(binLocations.filter((location) => (
            location.id !== id
          ))))
    }

    const getPoPdfs = (ids) => {
        new PurchaseOrderService()
            .printPOs(ids)
            .then(data => {
                console.log(data);
                const file = new Blob([data], { type: "application/pdf"})
                const fileURL = URL.createObjectURL(file)
                const pdfWindow = window.open()
                pdfWindow.location.href = fileURL
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getPickTickets = () => {
        let ids = binLocations.map((location, index) => location.purchase_order_id);
        getPoPdfs(ids);
    }

    const formattedBinLocations = () => {
        return binLocations.map(bl => formatBinLocationForTable(bl))
    }

    const formatBinLocationForTable = (binLocation) => {
        let {id, purchase_order_id, ...result} = {...binLocation} // Clone object instead of mutating it

        result.items_in_bin = result.items_in_bin.map( item => item.item).join("\n")
        result.items_on_po = result.items_on_po.map( item => item.item).join("\n")
        result.customer_po = <Link className={classes.poLink} to={"/admin/purchase-order-detail/"+binLocation.purchase_order_id}>{result.customer_po}</Link>
        result.clear_button = <a className={classes.clearButton} onClick={() => {if(window.confirm('Are you sure?')){clearBin(binLocation.id)};}} ><Clear /></a>

        return Object.values(result)
    }

    useEffect(async () => {
        getBinLocationData(
            {
                page: page,
                per_page: pageSize
            }
        )
    },[page, pageSize])

    const classes = useStyles();

    return (
        <div style={{position: 'relative'}}>
          <h3 className={classes.pageTitle}>Shipping Bins</h3>
          <LoadingSpinner loading={loading} />
          <BinLocationTable binLocations={formattedBinLocations()} getPickTickets={getPickTickets} paginationProps={paginationProps}>

          </BinLocationTable>
        </div>
    );
}
