import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import {Link} from "react-router-dom";

const customStyles = {
  tableCellTotal: {
    fontWeight: "500",
    fontSize: "1.25em",
    paddingTop: "14px",
    textAlign: "left",
  },
  tableCellAmount: {
    fontSize: "1.25em",
    marginTop: "5px",
    textAlign: "center",
  },
}
const useStyles = makeStyles(styles);
const useCustomStyles = makeStyles(customStyles);

export default function CustomTable(props) {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const {categoryName} = props;

  const date_group = (index)=> {
    var posibilities = ["ALL","LATE", "TODAY", "TOMORROW", "LATER"]
    return(posibilities[index])
  }

  const parmString = (category, index)=> {
    if(category == "Grand Totals")
      return("?date_group="+date_group(index))
    else
      return("?categories="+categoryName+"&date_group="+date_group(index))
  }


  const {
    tableHead,
    tableData,
    tableHeaderColor,
    hover,
    colorsColls,
    coloredColls,
    customCellClasses,
    customClassesForCells,
    striped,
    tableShopping,
    customHeadCellClasses,
    customHeadClassesForCells,
  } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor]}>
            <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
              {tableHead.map((prop, key) => {
                const tableCellClasses =
                  classes.tableHeadCell +
                  " " +
                  classes.tableCell +
                  " " +
                  cx({
                    [customHeadCellClasses[
                      customHeadClassesForCells.indexOf(key)
                    ]]: customHeadClassesForCells.indexOf(key) !== -1,
                    [classes.tableShoppingHead]: tableShopping,
                    [classes.tableHeadFontSize]: !tableShopping,
                  });
                return (
                  <TableCell className={tableCellClasses} key={key}>
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            var rowColor = "";
            var rowColored = false;
            if (prop.color !== undefined) {
              rowColor = prop.color;
              rowColored = true;
              prop = prop.data;
            }
            const tableRowClasses = cx({
              [classes.tableRowBody]: true,
              [classes.tableRowHover]: hover,
              [classes[rowColor + "Row"]]: rowColored,
              [classes.tableStripedRow]: striped && key % 2 === 0,
            });
            if (prop.total) {
              return (
                <TableRow key={key} hover={hover} className={tableRowClasses}>
                  <TableCell
                    className={classes.tableCell}
                  />
                  <TableCell
                      className={classes.tableCell}
                  />
                  <TableCell
                    className={classes.tableCell + " " + classes.tableCellTotal}
                  >
                    Total
                  </TableCell>
                  { prop.totals.map((innerProp, key) => {
                    return (
                        <TableCell
                        key={key}
                        className={
                          classes.tableCell + " " + customClasses.tableCellAmount
                        }
                        >
                          <Link  to={"/admin/purchase-orders"+parmString(categoryName, key)} >{innerProp}</Link>
                        </TableCell>)
                  })}
                </TableRow>
              );
            }
            if (prop.purchase) {
              return (
                <TableRow key={key} hover={hover} className={tableRowClasses}>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={prop.colspan}
                  />
                  <TableCell
                    className={classes.tableCell + " " + classes.right}
                    colSpan={prop.col.colspan}
                  >
                    {prop.col.text}
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow
                key={key}
                hover={hover}
                className={classes.tableRow + " " + tableRowClasses}
              >
                {prop.map((prop, key) => {
                  const tableCellClasses =
                    classes.tableCell +
                    " " +
                    cx({
                      [classes[colorsColls[coloredColls.indexOf(key)]]]:
                        coloredColls.indexOf(key) !== -1,
                      [customCellClasses[customClassesForCells.indexOf(key)]]:
                        customClassesForCells.indexOf(key) !== -1,
                    });
                  return (
                    <TableCell className={tableCellClasses} key={key}>
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
};

