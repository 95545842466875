import React, {useEffect, useState} from "react";

// @material-ui/core components
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// styles
import { makeStyles } from "@material-ui/core/styles";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

customSelectStyle["selectMenuItem"]["&:hover"] = {
  backgroundColor: "rgba(0,0,0,0.1)",
  boxShadow: "none",
  color: "inherit",
}
customSelectStyle["selectMenuItemSelected"]["color"] = "white!important" // To overwrite the hover effect color

const useStyles = makeStyles(customSelectStyle);

/*
This a canned multi select component that supports shift click out of the box
 */

export default function MultiSelect({title, values, options, onChange, onSubmit, autoFocus}) {
  const [showSelect, setShowSelect] = useState(null)
  const classes = useStyles()
  // checks if options are given as an array of strings or array of arrays (with separate key/value)
  const onlyValues = !Array.isArray(options[0])

  const onKeyDown = async (event) => {
    if (event.key === 'Enter') {
      setShowSelect(false)
      await onSubmit(event)
      await setShowSelect(null)
    }
  }

  const changeWithShiftClick = (e) => {
    let newSelectedOptions = e.target.value
    if (e.shiftKey && e.target.value.length >= 2){
      let lastTwo = e.target.value.slice(-2).sort()
      let allOptions = onlyValues ? options : options.map(ele => ele[1])
      let elementsBetweenSelected = allOptions.slice(allOptions.indexOf(lastTwo[0])+1, allOptions.indexOf(lastTwo[1]))
      newSelectedOptions = [...new Set([...newSelectedOptions, ...elementsBetweenSelected])]
    }
    onChange(newSelectedOptions)
  }

  return (
    <div onKeyDown={onKeyDown} key={showSelect}>
      <InputLabel
        htmlFor={`select-${title}`}
        className={classes.selectLabel}
      >
        {title}
      </InputLabel>
      <Select
        MenuProps={{
          autoFocus: autoFocus === undefined ? true : autoFocus,
          className: classes.selectMenu,
          getContentAnchorEl: () => null,
        }}
        classes={{
          select: classes.select,
        }}
        multiple
        value={values}
        onChange={changeWithShiftClick}
        inputProps={{
          name: `simpleSelect${title}`,
          id: `select-${title}`,
        }}
        style={{width: "100%"}}
        title={`Selected: ${values.join(', ')}`}
      >
        {options.map((option) => (
          <MenuItem
            key={onlyValues ? option : option[1]}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={onlyValues ? option : option[1]}
          >
            {onlyValues ? option : option[0]}
          </MenuItem>
        ))
        }
      </Select>
    </div>
  )
}
