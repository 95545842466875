import request from 'services/Service.js';
import { BASE_PRINT_STATION_API } from 'variables/api_urls';

export default class PrintStationService {
  constructor() {
    this.check_shipping_endpoint = `${BASE_PRINT_STATION_API}/check_shipping_label`;
    this.check_split_order = `${BASE_PRINT_STATION_API}/check_split_order`;
    this.retrieve_endpoint = `${BASE_PRINT_STATION_API}`;
    this.put_item_in_bin = `${BASE_PRINT_STATION_API}/put_item_in_bin`;
    this.ship_po = `${BASE_PRINT_STATION_API}/ship_po`;
    this.bypass_split_order = `${BASE_PRINT_STATION_API}/bypass_split_order`;
    this.upc_endpoint = `${BASE_PRINT_STATION_API}/get_upc`;
    this.packing_list_endpoint = `${BASE_PRINT_STATION_API}/get_packing_list`;
    this.purchase_order_endpoint = `${BASE_PRINT_STATION_API}/get_purchase_order`;
    this.create_shipment_endpoint = `${BASE_PRINT_STATION_API}/create_shipment`;
    this.qz_tray_cert = `${BASE_PRINT_STATION_API}/qz_tray_cert`;
    this.sign_print_request = `${BASE_PRINT_STATION_API}/sign_print_request`;

  }

  checkShippingLabel(poiid) {
    return request({
      method: 'get',
      url: `${this.check_shipping_endpoint}/${poiid}`
    })
  }

  checkSplitOrder(poiid) {
    return request({
      method: 'get',
      url: `${this.check_split_order}/${poiid}`
    })
  }

  putItemInBin(poiid, binName, flag) {
    return request({
      method: 'post',
      url: `${this.put_item_in_bin}/${poiid}/${binName}?${flag}`
    })
  }

  bypassSplitOrder(poiid) {
    return request({
      method: 'post',
      url: `${this.bypass_split_order}/${poiid}`
    })
  }

  shipPO(poiid, flag) {
    return request({
      method: 'post',
      url: `${this.ship_po}/${poiid}?${flag}`
    })
  }

  retrieve(id) {
    return request({
      method: 'get',
      url: `${this.retrieve_endpoint}/${id}`
    })
  }

  getUPC(id) {
    return request({
      method: 'get',
      url: `${this.upc_endpoint}/${id}`
    })
  }

  getPackingListURL(id) {
    return request({
      method: 'get',
      url: `${this.packing_list_endpoint}/${id}`
    })
  }

  getPurchaseOrder(id){
    return request({
      method: 'get',
      url: `${this.purchase_order_endpoint}/${id}`
    })
  }

  createShipment(id,poiids){
    return request({
      method: 'post',
      data: {poiids: poiids},
      url: `${this.create_shipment_endpoint}/${id}`
    })
  }

  getQzCertificate(){
    return request({
      method: 'get',
      cache: 'no-store',
      url: this.qz_tray_cert
    })
  }

  getQzSignature(key){
    return request({
      method: 'get',
      cache: 'no-store',
      url: `${this.sign_print_request}/${key}`
    })
  }

}
