import React, {Component, useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";


const styles = {
  modal: {
    minWidth: "400px!important",
    width: "400px"
  },
  skuTable: {
    marginTop: "-50px",
    whiteSpace: "pre-line",
    '& td:nth-child(0)': {
      width: "1px",
    },
    '& td:nth-child(1)': {
      width: "100%",
    },
    '& td': {
      paddingTop: '0!important',
      paddingBottom: '0!important'
    },
    '& .MuiButtonBase-root': {
      padding: "0!important"
    },
    '& tr': {
      height: "35px"
    }

  },
  filterActionSeperator: {
    margin:"0"
  },
}

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function SimpleSearchModal({data, tableHeader, modalDisplay, closeModal, clearOptions, applySearch}) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={modalDisplay}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <Card plain>
          <CardBody plain>
            <div className={classes.skuTable}>
              <Table
                hover
                tableHead={tableHeader}
                tableData={data}
                className={classes.tableBody}
              />
            </div>
          </CardBody>
        </Card>
      </DialogContent>
      <hr className={classes.filterActionSeperator}/>
      <DialogActions className={classes.modalFooter}>
        <GridItem xs={12} sm={4}>
          <Button color="transparent" onClick={() => {applySearch(); closeModal()}} >Apply</Button>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Button
            color="transparent"
            onClick={() => {clearOptions(), closeModal()}}
          >
            Clear
          </Button>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Button
            onClick={closeModal}
            color="danger"
            simple
          >
            Close
          </Button>
        </GridItem>
      </DialogActions>
    </Dialog>
  )
}

export default SimpleSearchModal