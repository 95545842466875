import Dashboard from "views/Dashboard/Dashboard.js";

import PurchaseOrderListView from "views/PurchaseOrders/List.js"
import PoDetails from "views/PurchaseOrders/PoDetails.js"

import DashboardView from "views/Dashboard/Dashboard.js"
import BinLocationView from "views/BinLocations/List.js"
import CutlistView from "views/Reporting/Cutlist.js"

import PrintLabels from "views/Shipping/PrintLabels.js";
import PrintUpcs from "views/Shipping/PrintUpcs.js";
import PrintPackingList from "views/Shipping/PrintPackingList.js";
import EndOfDay from "views/Shipping/EndOfDay.js";
import LoginPage from "views/Pages/LoginPage.js";

import ProductionSummary from "views/Reporting/ProductionSummary.js";
import RequestReset from "views/Pages/RequestReset";
import ResetPassword from "views/Pages/ResetPassword";

import ReactTables from "views/Tables/ReactTables";

// @material-ui/icons
// import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
// import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import Equalizer from '@material-ui/icons/Equalizer';
import Print from '@material-ui/icons/Print';
import Inbox from '@material-ui/icons/Inbox';
import Assignment from '@material-ui/icons/Assignment';
import CalendarToday from '@material-ui/icons/CalendarToday';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import BulkPackingSlip from "./views/Reporting/BulkPackingSlip";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "الرسوم البيانية",
    icon: Timeline,
    component: DashboardView,
    layout: "/admin",
  },
  {
    path: "/purchase-orders",
    name: "Purchase Order List",
    rtlName: "الحاجيات",
    icon: FormatListBulleted,
    component: PurchaseOrderListView,
    layout: "/admin",
  },
  {
    path: "/print-shipping-labels",
    name: "Print Station",
    rtlName: "الحاجيات",
    icon: Print,
    component: PrintLabels,
    layout: "/admin",
  },
  // {
  //   collapse: true,
  //   name: "Purchase Orders",
  //   rtlName: "الجداول",
  //   icon: GridOn,
  //   state: "poCollapse",
  //   views: [
  //     // {
  //     //   path: "/purchase-orders",
  //     //   name: "Purchase Order List",
  //     //   rtlName: "الحاجيات",
  //     //   icon: WidgetsIcon,
  //     //   component: PurchaseOrderListView,
  //     //   layout: "/admin",
  //     // },
  //     {
  //       path: "/purchase-order-detail",
  //       name: "Purchase Order Detail",
  //       rtlName: "الحاجيات",
  //       icon: WidgetsIcon,
  //       component: PoDetails,
  //       layout: "/admin",
  //     },
  //   ]
  // },
  {
    path: "/bin-locations",
    name: "Shipping Bins",
    rtlName: "الرسوم البيانية",
    icon: Inbox,
    component: BinLocationView,
    layout: "/admin",
  },
  {
    path: "/end-of-day",
    name: "End-of-Day Shipping",
    rtlName: "الحاجيات",
    icon: LocalShipping,
    component: EndOfDay,
    layout: "/admin",
  },
  {
    path: "/bulk-packing-slips",
    name: "Bulk Packing Slips",
    rtlName: "الرسوم البيانية",
    icon: Assignment,
    component: BulkPackingSlip,
    layout: "/admin",
  },

  {
    path: "/cutlist",
    name: "Cutlist",
    rtlName: "الرسوم البيانية",
    icon: CalendarToday,
    component: CutlistView,
    layout: "/admin",
  },

  {
    path: "/login-page",
    name: "Manufacturing Portal",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
    hide: true
  },
  {
    path: "/request-reset-page",
    name: "Manufacturing Portal",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: RequestReset,
    layout: "/auth",
    hide: true
  },
  {
    path: "/reset-password-page",
    name: "Manufacturing Portal",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: ResetPassword,
    layout: "/auth",
    hide: true
  },
  {
    path: "/purchase-order-detail/:id",
    name: "Purchase Order Detail",
    rtlName: "الحاجيات",
    icon: WidgetsIcon,
    component: PoDetails,
    layout: "/admin",
    hide: true
  },

];
export default dashRoutes;
