/*eslint-disable*/
import React, {useEffect, useState} from "react";

// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import PreviousScanFormsTable from "app_components/ScanForms/PreviousScanFormsTable";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import LocalShipping from "@material-ui/icons/LocalShipping";
import InputLabel from "@material-ui/core/InputLabel";

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "15px",
  },
  scanFormTable: {
    whiteSpace: "pre-line",
    '& td:nth-child(1)': {
      width: "1px",
    },
    '& td:nth-child(2)': {
      width: "100%",
    }
  },
  manifestError: {
    color: "var(--bs-danger)",
    opacity: ".8",
  },
  submitManifest: {
    position: "relative",
    float: "right",
    alignSelf: "flex-end",
    '& button': {
      marginBottom: "17px"
    },
  },
  carrierSelect: {
    paddingLeft: "15px",
    marginTop: "0!important",
    "& $MuiInput-underline::after": {
    },
    "& div div": {
      textTransform: "none!important",
      paddingLeft: "20px",
      paddingBottom: "12px",
    },
    "& div svg": {
      marginRight: "10px",
    }
  },
  btnFloat: {
    position: "relative",
    float: "right",
    paddingRight: "15px"
  }
};

const useStyles = makeStyles(newStyles);

// Our table component
function ManifestForm({manifests, paginationProps, setSelectedCarrier, submitManifest, error}) {
  const classes = useStyles()
  const renderError = () => {
    return error.length === 0 ? '' : <GridItem xs={12}><p className={classes.manifestError}>{error}</p></GridItem>
  }

  return (
    <div style={{overflowX:"hidden"}} className="toggle-visibility ">
      <GridContainer>
        <GridItem xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="warning">
              <LocalShipping />
            </CardIcon>
          </CardHeader>
        <CardBody>
        {renderError()}
        <GridContainer justifyContent="center">
        <GridItem xs={6}>
        <FormControl fullWidth className={classes.selectFormControl + " " + classes.carrierSelect}>
          <h4 className={classes.cardIconTitle}>Generate Manifest</h4>
          <Select
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            onChange={(event) => {
              setSelectedCarrier(event.target.value)
            }}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem
              }}
            >
              Select Carrier
            </MenuItem>
            <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="DhlEcs"
            >
              DHL eCommerce
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="DHLGlobalMail"
            >
              DHLGlobalMail
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="DHLGlobalMailInternational"
            >
              DHLGlobalMailInternational
            </MenuItem>
          </Select>
        </FormControl>
        </GridItem>
        <GridItem xs={6} className={classes.submitManifest}>
          <FormControl className={classes.btnFloat}>
            <Button color="primary" onClick={() => submitManifest()}>Submit</Button>
          </FormControl>
        </GridItem>
        </GridContainer>
          <GridItem xs={12}>
      <hr style={{color:'#eee', opacity: '1'}} />
      <PreviousScanFormsTable manifests={manifests} paginationProps={paginationProps} />
          </GridItem>
        </CardBody>
        </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}


export default ManifestForm;