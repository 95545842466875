import React, {useEffect, useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

// services
import BulkPackingSlipService from "services/BulkPackingSlipService.js";

// core components
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SimplePagination from "app_components/SimplePagination.js";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Button from "components/CustomButtons/Button.js";

const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    bulkPackingSlipContainer: {
        maxWidth: "800px",
        margin: "auto",
    },
    packingSlipTable: {
        '& td:nth-child(2), & th:nth-child(2)': {
            whiteSpace: "nowrap",
            paddingLeft: "30px !important",
        },
        '& td:nth-child(3), & th:nth-child(3)': {
            width: "100%",
            paddingLeft: "30px !important",
        }
    },
    pageTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center",
    },
    pdfButton: {
        padding: "5px!important",
        marginLeft: "20px",
        '& svg': {
            margin: "0"
        }
    },
    btnCol: {
        width: "100px"
    }
};

const useStyles = makeStyles(styles);

export default function () {
    const formatBulkPackingSlipForTable = (bulkPackingSlip) => {
        let {url, available, ...result} = {...bulkPackingSlip} // Clone object instead of mutating it
        result.pdfButton = bulkPackingSlip.available ? <div className={classes.btnCol}>
            <Button color='primary'
                    size="sm"
                    className={classes.pdfButton}
                    onClick={() => window.open().location.href = bulkPackingSlip.url}
            ><OpenInNew /></Button>
        </div> : "Not Available"

        return Object.values(result)
    }

    const setPageSize = (rows) => {
        localStorage.setItem('number_of_rows_for_bps', JSON.stringify(rows))
        setPageSizeState(rows)
    }

    // BPS props
    const [bulkPackingSlipData, setBulkPackingSlipData] = useState([])

    // Pagination Props
    const [page, setPage] = useState(1)
    const [pageSize, setPageSizeState] = useState(JSON.parse(localStorage.getItem('number_of_rows_for_bps')) || 10)
    const [pageTotal, setPageTotal] = useState(1)
    const paginationProps = {
        page,
        pageSize,
        pageTotal,
        setPage,
        setPageSize
    }

    const classes = useStyles();
    let bulkPackingSlipService = new BulkPackingSlipService()

    const getBulkPackingSlipData = async (params) => {
        bulkPackingSlipService.retrieve(params)
            .then((data) => {
                setBulkPackingSlipData(data["bulk_packing_list_data"])
                setPageTotal(data["total_pages"])
            })
    }

    useEffect(() => {
        getBulkPackingSlipData({
            page: page,
            per_page: pageSize
        });
    }, [page, pageSize])

    return (
        <>
            <h3 className={classes.pageTitle}>Bulk Packing Slips</h3>
            <div className={classes.bulkPackingSlipContainer}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="warning">
                                    <Assignment />
                                </CardIcon>
                            </CardHeader>
                            <CardBody>
                                <SimplePagination {...paginationProps}/>
                                <div className={classes.packingSlipTable}>
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["ID", "Created", "Purchase Orders", "Link to PDF"]}
                                        tableData={bulkPackingSlipData.map(bps => formatBulkPackingSlipForTable(bps))}
                                        coloredColls={[3]}
                                        colorsColls={["info"]}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </>
    )
}