const Dymo = require('dymojs'),
    dymo = new Dymo();

const getDymoPrinter = () => {
    dymo.getPrinters().then(function (printers) {
console.log("GOT PRINTERS: ")
console.log(printers)
        printers = printers.replaceAll("\\","").replaceAll("\\n","").replaceAll("\"","")
        printers = printers.replace('<?xml version=1.0 encoding=utf-8?>n', '')
        printers = printers.substring(0, printers.length - 1);
console.log(printers)
        if (printers.length == 0) {
            throw "No DYMO printers are installed. Install DYMO printers.";
        }
        var parser = new DOMParser();
        var pxml = parser.parseFromString(printers, "application/xml");
console.log(pxml)
        var labelWriters = pxml.getElementsByTagName("LabelWriterPrinter");
        var printerName = "";

        for (var i = 0; i < labelWriters.length; ++i) {
            var printer = labelWriters[i].getElementsByTagName("Name")[0].textContent;
            var connected = labelWriters[i].getElementsByTagName("IsConnected")[0].textContent;

            if (printer.includes("DYMO")) {
                if(connected == "True") {
                    return printer;
                } else {
                    throw "DYMO LabelWriter printer is configured for this shipping station, but is not connected."
                }
            }
        }

        if (printerName == "") {
            throw "No DYMO LabelWriter printers found. Install DYMO LabelWriter printer";
        }

        return "";
    })
    .catch((error) => {
        alert(error);
    });
}

const printBarcodeLabel = (text, printer) => {
    console.log('PRINTING BARCODE TO DYMO PRINTER ' + text)

    try {
        // open label
        var labelXml = `<?xml version="1.0" encoding="utf-8"?>\
      <DieCutLabel Version="8.0" Units="twips">\
        <PaperOrientation>Landscape</PaperOrientation>\
        <Id>Address</Id>\
        <PaperName>30252 Address</PaperName>\
        <DrawCommands>\
          <RoundRectangle X="0" Y="0" Width="1581" Height="5040" Rx="270" Ry="270"/>\
        </DrawCommands>\
        <ObjectInfo>\
          <BarcodeObject>\
            <Name>${text}</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
            <BackColor Alpha="255" Red="255" Green="255" Blue="255"/>\
            <LinkedObjectName></LinkedObjectName>\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <Text>${text}</Text>\
            <Type>Code128Auto</Type>\
            <Size>Medium</Size>\
            <TextPosition>Bottom</TextPosition>\
            <TextFont Family="Lucida Grande" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
            <CheckSumFont Family="Lucida Grande" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
            <TextEmbedding>None</TextEmbedding>\
            <ECLevel>0</ECLevel>\
            <HorizontalAlignment>Center</HorizontalAlignment>\
            <QuietZonesPadding Left="0" Right="0" Top="0" Bottom="0"/>\
          </BarcodeObject>\
          <Bounds X="330" Y="213" Width="4325" Height="1220"/>\
        </ObjectInfo>\
      </DieCutLabel>`;
        console.log(printer)
        console.log(labelXml)
        dymo.print(printer, labelXml);
    } catch
        (e) {
        alert(e.message || e);
    }
}


export { printBarcodeLabel, getDymoPrinter }
