/*eslint-disable*/
import React, {useEffect} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import SimplePagination from "app_components/SimplePagination.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Assignment from "@material-ui/icons/Assignment";

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  retainWhiteSpace: {
    whiteSpace: "pre-line",
  },
  printPickTickets: {
    position: "absolute",
    top: "5px",
    right: "0",
    zIndex: "1"
  }
};

const useStyles = makeStyles(newStyles);

// Our table component
function BinLocationTable({binLocations, getPickTickets, paginationProps}) {
  console.log(binLocations)
  const classes = useStyles()
  return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="warning">
                  <Assignment/>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <SimplePagination {...paginationProps}/>
                <div className={classes.retainWhiteSpace}>
                  <Table
                    tableHead={["Bin Name", "Items in Bin", "Items on PO", "Customer PO", "Bin Status", "Assigned At", "Actions"]}
                    tableData={Object.values(binLocations)}
                    coloredColls={[6]}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
  );
}


export default BinLocationTable;
