import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Table from "./CustomTable.js";
import {dangerColor, successColor, warningColor, grayColor} from "assets/jss/material-dashboard-pro-react";
const styles = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    qtyHeader: {
        textAlign: "center",
        whiteSpace: "nowrap",
        width: "10%;",
    },
    productHeader:{
        textAlign: "left",
        width: "15%;",
    },
    total: {
        textAlign: "center",
        // width: "10%;",
    },
    late: {
        textAlign: "center",
        // width: "10%;",
        backgroundColor: dangerColor[5],
    },
    today: {
        textAlign: "center",
        // width: "10%;",
        backgroundColor: warningColor[5],
    },
    tomorrow: {
        textAlign: "center",
        // width: "10%;",
        backgroundColor: grayColor[5],
    },
    later: {
        textAlign: "center",
        // width: "10%;",
        backgroundColor: successColor[5],
    },
    product: {
        textAlign: "left",
        // width: "20%;",
    }
};

const useStyles = makeStyles(styles);
export default function CutListCategoryTable({tableData, tableHead}) {
    const classes = useStyles();
    return (
        <Table
            tableHeaderColor="primary"
            tableHead={tableHead}
            tableData={tableData.rows}
            categoryName={tableData.name}
            customCellClasses={[
                classes.product,
                classes.product,
                classes.total,
                classes.late,
                classes.today,
                classes.tomorrow,
                classes.later,
            ]}
            customClassesForCells={[0, 1 , 3, 4, 5, 6, 7]}
            customHeadCellClasses={[
                classes.productHeader,
                classes.productHeader,
                classes.productHeader,
                classes.qtyHeader,
                classes.qtyHeader,
                classes.qtyHeader,
                classes.qtyHeader,
                classes.qtyHeader,
                classes.qtyHeader,
            ]}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
        />
    )
}