import React from "react";
import {Link} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";



import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import AuthService from "../../services/AuthService";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
    const {history} = props;
    const isLoggedIn = props.isLoggedIn;
    const [registerEmailState, setregisterEmailState] = React.useState("");
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const [loginEmail, setLoginEmail] = React.useState('');

    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    const requestReset = () => {
        new AuthService().requestReset(loginEmail)
            .then((data) => {
                console.log('back from login call');
                console.log(data);
                if(data.result == 'success') {
                    alert(data.message);
                    history.push('/auth/login-page')

                }
                else {
                    alert(data.message);
                    setLoginEmail('');
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleSubmit = (e) => {
        console.log
        e.preventDefault();
        requestReset();
    }

    const verifyEmail = (value) => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };


    const classes = useStyles();
    return (
        <div className={classes.container}>
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={6} md={4}>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={12} md={12}>
                            <h2 align="center">Manufacturing Portal</h2>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <form onSubmit={handleSubmit}>
                                <Card login className={classes[cardAnimaton]}>
                                    <CardHeader
                                        className={`${classes.cardHeader} ${classes.textCenter}`}
                                        color="rose"
                                    >
                                        <h4 className={classes.cardTitle}>Request Password Reset</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <CustomInput
                                            labelText="Email..."
                                            id="email"
                                            success={registerEmailState === "success"}
                                            error={registerEmailState === "error"}
                                            // onChange={alert(1)}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: loginEmail,
                                                onChange: (event) => {
                                                    if (verifyEmail(event.target.value)) {
                                                        console.log(1)
                                                        setregisterEmailState("success");
                                                    } else {
                                                        console.log(2)
                                                        setregisterEmailState("error");
                                                    }
                                                    setLoginEmail(event.target.value);
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Email className={classes.inputAdornmentIcon} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </CardBody>
                                    <CardFooter className={classes.justifyContentCenter}>
                                        <Button size="sm" color="rose"
                                                onClick={handleSubmit}
                                        >Submit</Button>
                                    </CardFooter>
                                </Card>

                            </form>
                        </GridItem>
                    </GridContainer>

                </GridItem>
            </GridContainer>
        </div>
    );
}
